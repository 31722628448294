import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "addresses/";
import authHeader from "../services/auth-header";
export const addresses = {
  state: {
    addresses: [],
  },
  getters: {
    allAdd: (state) => state.addresses,
  },
  mutations: {
    getAdd(state, addresses) {
      state.addresses = addresses;
    },
    clearAddress(state) {
      state.addresses = [];
    },
    createAddress(state, address) {
      state.addresses.unshift(address);
    },
    updateAddress(state, address) {
      const index = state.addresses.findIndex((c) => c.id === address.id);
      state.addresses.splice(index, 1, address);
    },
    deleteAdd(state, id) {
      const index = state.addresses.findIndex((c) => c.id === id);
      state.addresses.splice(index, 1);
    },
  },
  actions: {
    async getAdd({ commit }, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          commit("getAdd", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },

    createAddress({ commit }, addresses) {
      console.log(addresses);
      return axios
        .post(apiURL, addresses, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("createAddress", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    deleteAdd({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("deleteAdd", id);
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    updateAddress({ commit }, data) {
      console.log(data);
      return axios
        .put(apiURL + data.id, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("updateAddress", data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    checkAddress({ commit }, data) {
      console.log(data);
      return axios
        .post(apiURL + "checkAddress", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
  },
};
