<template>
  <v-layout v-resize="onResize" column>
    <div>
      <template v-if="!$route.meta.allowAnonymous">
        <template v-if="isMobile">
          <v-app style="background: #f5f5f5">
            <sidebar />
            <v-main class="mb-15">
              <router-view></router-view>
            </v-main>
            <BottomNavigation />
          </v-app>
        </template>
        <template v-else>
          <v-app style="background: #f5f5f5">
            <sidebar />

            <v-main>
              <!-- Provides the application the proper gutter -->

              <!-- If using vue-router -->
              <router-view></router-view>
            </v-main>
          </v-app>
        </template>
      </template>
      <template v-else>
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
    </div>
  </v-layout>
</template>

<script>
import sidebar from "./views/Sidebar.vue";

import BottomNavigation from "./views/BottomNavigation.vue";
export default {
  name: "App",
  components: {
    sidebar,
    BottomNavigation,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    onResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cf0808;
}
</style>
