import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "config/";
import authHeader from "../services/auth-header";
export const configs = {
  state: {
    configs: [],
  },
  getters: {
    allConfigs: (state) => state.configs,
  },
  mutations: {
    getConfigs(state, configs) {
      state.configs = [];
      if (configs == null) {
        state.configs = [];
      } else {
        state.configs.push(configs);
      }
    },
    createConfigs(state, config) {
      state.configs.unshift(config);
    },
    editConfig(state, config) {
      console.log(config);
      const index = state.configs.findIndex((u) => u._id === config._id);
      state.configs.splice(index, 1, config);
    },
    deleteConfig(state, id) {
      const index = state.configs.findIndex((u) => u._id === id);
      state.configs.splice(index, 1);
    },
  },
  actions: {
    async getConfigs(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("getConfigs", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createConfig(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            building: data.building,
            endWith: data.endWith,
            moduleFormat: data.moduleFormat,
            timeFormat: data.timeFormat,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          context.commit("createConfigs", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return "Error: Sorry,that username already exists!";
        });
    },
    async editConfig(context, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data._id,
          {
            building: data.building,
            endWith: data.endWith,
            moduleFormat: data.moduleFormat,
            timeFormat: data.timeFormat,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("editConfig", data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async deleteConfig(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
          context.commit("deleteConfig", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
