<template>
  <div>
    <v-container fluid class="ma-1">
      <h1>
        {{ this.$router.currentRoute.name }}
      </h1>

      <v-tabs v-model="tab">
        <v-tab>Key words Panel</v-tab>
        <v-tab>Key words Zone</v-tab>
        <v-tab-item>
          <v-card id="card">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                ref="search"
                v-model="searchpanel"
                prepend-inner-icon="mdi-magnify"
                class="shrink"
                style="width: 300px"
                color="#D32F2F"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              item-key="id"
              :search="searchpanel"
              :headers="headers"
              :items="getPanel"
              :items-per-page="5"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="success"
                  class="mx-2"
                  icon
                  @click="opendialogPanel(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="getAccess.username == 'pmAdmin'"
                  color="error"
                  @click="delData(item._id)"
                  icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card id="card">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                ref="search"
                v-model="searchzone"
                prepend-inner-icon="mdi-magnify"
                class="shrink"
                style="width: 300px"
                color="#D32F2F"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              item-key="id"
              :search="searchzone"
              :headers="headers"
              :items="getZone"
              :items-per-page="5"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="success"
                  class="mx-2"
                  icon
                  @click="opendialogZone(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="getAccess.username == 'pmAdmin'"
                  color="error"
                  @click="delData(item._id)"
                  icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-row class="mt-1">
        <v-col> </v-col>
        <v-col> </v-col>
      </v-row>

      <v-dialog v-model="dialog" width="400" scrollable>
        <template>
          <v-card width="500">
            <v-card-title
              v-if="tab == 0"
              class="headline red darken-2 white--text"
            >
              Edit Keyword Panel
            </v-card-title>
            <v-card-title v-else class="headline red darken-2 white--text">
              Edit Keyword Zone
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="saveKeyword()"
                ref="keywordData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="action"
                    id="id"
                    label="Action"
                    hide-details
                    v-model="keywordData.action"
                    disabled
                    required
                  ></v-text-field>
                  <v-text-field
                    name="keyword"
                    id="id"
                    label="Keyword*"
                    hide-details
                    v-model="keywordData.keyword"
                    :rules="[(v) => !!v || 'Address is required']"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close()"> Cancel </v-btn>
              <v-btn id="btnsave" color="success" text @click="saveKeyword()">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="addDialog" width="400" scrollable>
        <template>
          <v-card width="500">
            <v-card-title
              v-if="tab == 0"
              class="headline red darken-2 white--text"
            >
              Add Keyword Panel
            </v-card-title>
            <v-card-title v-else class="headline red darken-2 white--text">
              Add Keyword Zone
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="addKeyword()"
                ref="keywordData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="action"
                    id="id"
                    label="Action"
                    hide-details
                    v-model="keywordData.action"
                    required
                  ></v-text-field>
                  <v-text-field
                    name="keyword"
                    id="id"
                    label="Keyword*"
                    hide-details
                    v-model="keywordData.keyword"
                    :rules="[(v) => !!v || 'Address is required']"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close()"> Cancel </v-btn>
              <v-btn id="btnsave" color="success" text @click="addKeyword()">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="confirmDelete" max-width="330">
        <v-card>
          <v-card-title primary-title v-if="tab == 0">
            Confirm Delete Keyword Panel
          </v-card-title>
          <v-card-title primary-title v-else>
            Confirm Delete Keyword Zone
          </v-card-title>
          <v-card-text class="body">
            Are you sure to delete this keyword ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDelete = false"> Cancel </v-btn>

            <v-btn color="error" text @click="delConfig()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" top right :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
      <v-btn
        style="position: fixed; bottom: 25px; right: 0px"
        class="ma-10"
        fixed
        fab
        color="#D63636"
        dark
        absolute
        bottom
        right
        dense
        floating
        @click="addDialog = true"
        v-if="getAccess.username == 'pmAdmin'"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import VueResizable from "vue-resizable";
import DDR from "yoyoo-ddr";
import "yoyoo-ddr/dist/yoyoo-ddr.css";
export default {
  components: { VueResizable, DDR },
  data() {
    return {
      id: null,
      tab: 0,
      dialog: false,
      addDialog: false,
      searchzone: null,
      searchpanel: null,
      havePhoto: false,
      dataDelete: "",
      keywordData: {
        id: "",
        keyword: "",
      },
      default: {
        id: "",
        keyword: "",
      },
      color: null,
      text: null,
      snackbar: false,
      confirmDelete: false,
      headers: [
        {
          text: "Action",
          value: "action",
          sortable: true,
          width: "40%",
        },
        { text: "Keyword", value: "keyword", sortable: true, width: "40%" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    getPanel() {
      return this.$store.getters.allPanel;
    },
    getZone() {
      return this.$store.getters.allZone;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.$route.params.data != null) {
      this.id = this.$route.params.data.id;
      Cookies.set("dataBuilding", this.$route.params.data);
      Cookies.set("idBuilding", this.id);
    }
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role != "Superadmin") {
      this.$router.push("/");
    } else {
      this.loadKeywords();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    loadKeywords() {
      this.id = Cookies.get("idBuilding");
      this.$store.dispatch("getKeyword", this.id);
    },
    delData(id) {
      this.confirmDelete = true;
      this.dataDelete = id;

      console.log(this.dataDelete);
    },
    opendialogZone(item) {
      this.dialog = true;
      this.create = true;

      this.keywordData = Object.assign({}, item);
      this.keywordData.id = item._id;
    },
    opendialogPanel(item) {
      this.dialog = true;
      this.create = true;

      this.keywordData = Object.assign({}, item);
      this.keywordData.id = item._id;
    },
    close() {
      this.dialog = false;
      this.addDialog = false;
      this.keywordData = Object.assign({}, this.default);
      this.create = true;
      this.$refs.keywordData.resetValidation();
    },
    saveKeyword() {
      console.log(this.keywordData);
      if (this.tab == 0) {
        this.updatePanel();
      } else {
        this.updateZone();
      }
    },
    addKeyword() {
      console.log(this.keywordData);
      if (this.tab == 0) {
        this.createPanel();
      } else {
        this.createZone();
      }
    },
    updatePanel() {
      this.$store.dispatch("updatePanel", this.keywordData).then(
        () => {
          //this.loadKeywords();
          this.close();
          this.color = "green";
          this.text = "Keyword has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    updateZone() {
      console.log(this.keywordData);
      this.$store.dispatch("updateZone", this.keywordData).then(
        () => {
          //this.loadKeywords();
          this.close();
          this.color = "green";
          this.text = "Keyword has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    createZone() {
      console.log(this.keywordData);
      this.keywordData.building = this.id;
      this.$store.dispatch("createZone", this.keywordData).then(
        () => {
          //this.loadKeywords();
          this.close();
          this.color = "green";
          this.text = "Keyword has been successfully saved.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    createPanel() {
      console.log(this.keywordData);
      this.keywordData.building = this.id;
      this.$store.dispatch("createPanel", this.keywordData).then(
        () => {
          //this.loadKeywords();
          this.close();
          this.color = "green";
          this.text = "Keyword has been successfully saved.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    delConfig() {
      if (this.tab == 0) {
        this.$store
          .dispatch("deletePanel", this.dataDelete)
          .then(() => {
            this.confirmDelete = false;
            this.color = "success";
            this.text = "Deleted! keyword has been deleted.";
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.color = "error";
            this.text = " Error! You cannot delete this keyword.";
            this.snackbar = true;
          });
      } else {
        this.$store
          .dispatch("deleteZone", this.dataDelete)
          .then(() => {
            this.confirmDelete = false;
            this.color = "success";
            this.text = "Deleted! keyword has been deleted.";
            this.snackbar = true;
          })
          .catch((error) => {
            console.log(error);
            this.color = "error";
            this.text = " Error! You cannot delete this keyword.";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style></style>
