import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { buildings } from "./buildings.module";
import { events } from "./events.module";
import { reports } from "./reports.module";
import { accounts } from "./accounts.module";
import { companies } from "./companies.module";
import { settings } from "./settings.module";
import { maps } from "./maps.module";
import { lines } from "./lines.module";
import { addresses } from "./addresses.module";
import { keywords } from "./keywords.module.js";
import { realTime } from "./realTime.module.js";
import { configs } from "./configs.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    buildings,
    events,
    reports,
    accounts,
    companies,
    lines,
    maps,
    addresses,
    keywords,
    realTime,
    configs
  },
});
