<template>
  <div v-if="this.pageHome">
    <v-bottom-navigation
      fixed
      color="red"
      background-color="#000"
      dark
      v-model="path"
    >
      <v-btn @click="dashboard()" value="Dashboard" small>
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="reports()" value="Reports" small>
        <span>Reports</span>
        <v-icon>mdi-file-document</v-icon>
      </v-btn>
      <v-btn
        v-if="getAccess.role == 'Superadmin'"
        @click="accounts()"
        value="Accounts"
      >
        <span>Setting</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn
        v-if="getAccess.role == 'Admin'"
        @click="lineCompanies()"
        value="Companies"
      >
        <span>Line Company</span>
        <font-awesome-icon
          icon="fa-brands fa-line fa-lg"
          color="#F0219"
          style="font-size: 23px"
          class="ml-1"
        />
      </v-btn>
    </v-bottom-navigation>
  </div>
  <div v-else>
    <v-bottom-navigation
      
      fixed
      color="red"
      background-color="#000"
      dark
      v-model="path"
      v-if="!this.pageSetting"
    >
      <v-btn @click="dashboard()" value="Dashboard">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="event()" value="Events">
        <span>Events</span>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role != 'User'" @click="map()" value="Map">
        <span>Maps</span>
        <v-icon>mdi-map-marker-radius</v-icon>
      </v-btn>

      <v-btn
        v-if="getAccess.role == 'Superadmin'"
        @click="keyword()"
        value="Keywords"
      >
        <span>Keywords</span>
        <v-icon> mdi-clipboard-text</v-icon>
      </v-btn>
      <v-btn
        v-if="getAccess.role != 'User'"
        @click="line()"
        value="LineBuildings"
      >
        <span>Line Building</span>
        <font-awesome-icon
          icon="fa-brands fa-line fa-lg"
          color="#F0219"
          style="font-size: 23px"
          class="ml-1"
        />
      </v-btn>
      <v-btn
        v-if="this.getAccess.username == 'pmAdmin'"
        @click="config()"
        value="Configs"
      >
        <span>Configs</span>
        <v-icon>mdi-square-edit-outline</v-icon>
        
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      v-model="path"
      
      fixed
      color="red"
      background-color="#000"
      dark
      v-else
    >
      <v-btn @click="dashboard()" value="Dashboard">
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn @click="accounts()" value="Accounts">
        <span>Accounts</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn @click="company()" value="Companies">
        <span>Companies</span>
        <v-icon>mdi-sitemap</v-icon>
      </v-btn>
      <v-btn @click="building()" value="Buildings">
        <span>Buildings</span>
        <v-icon>mdi-office-building</v-icon>
      </v-btn>
      <v-btn v-if="getAccess.role == 'Admin'" @click="lineCompanies()">
        <span>Line</span>
        <font-awesome-icon
          icon="fa-brands fa-line fa-lg"
          color="white"
          style="font-size: 23px"
          class="ml-1"
        />
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  watch: {
    $route(to, from) {
      console.log(to.name);
      this.path = to.name;
      if (this.path == "LineCompanies") {
        this.path = "Companies";
      }
      console.log(this.path);
      if (to) {
        this.$Simplert.close();
      }
      if (
        to.name.search("Accounts") === 0 ||
        to.name.search("Companies") === 0 ||
        to.name.search("Buildings") === 0 ||
        to.name.search("LineCompanies") === 0
      ) {
        if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          this.pageSetting = false;
          this.pageBuilding = false;
          this.pageHome = true;
        } else {
          this.pageSetting = true;
          this.pageBuilding = false;
          this.pageHome = false;
        }
      } else if (
        to.name.search("Events") === 0 ||
        to.name.search("Keywords") === 0 ||
        to.name.search("LineBuildings") === 0 ||
        to.name.search("Map") === 0 ||
        to.name.search("Addresses") === 0 ||
        to.name.search("Configs") === 0 
      ) {
        this.pageSetting = false;
        this.pageBuilding = true;
        this.pageHome = false;
      } else {
        this.pageHome = true;
        this.pageBuilding = false;
        this.pageSetting = false;
      }
    },
  },
  created() {
    this.checkManu();
  },
  data() {
    return {
      isMobile: false,
      path: "Dashborad",
      pageHome: true,
      pageBuilding: false,
      pageSetting: false,
      idBuilding: null,
    };
  },
  methods: {
    dashboard() {
      this.$router.push("/dashboard");
    },
    reports() {
      this.$router.push("/reports");
    },
    accounts() {
      this.$router.push("/accounts");
    },
    event() {
      this.idBuilding = Cookies.get("idBuilding");
      this.$router.push(`/building/events/${this.idBuilding}`);
    },
    map() {
      this.$router.push("/building/map");
    },
    line() {
      this.$router.push("/building/lines");
    },
    lineCompanies() {
      this.$router.push("/lines");
    },
    company() {
      this.$router.push("/companies");
    },
    building() {
      this.$router.push("/buildings");
    },
    keyword() {
      this.$router.push("/building/keywords");
    },
    config() {
      this.$router.push("/building/configs");
    },
    checkManu() {
      this.path = this.$router.currentRoute.name;
      if (this.path == "LineCompanies") {
        this.path = "Companies";
      }
      console.log(this.path);
      if (
        this.path.search("Accounts") === 0 ||
        this.path.search("Companies") === 0 ||
        this.path.search("Buildings") === 0 ||
        this.path.search("LineCompanies") === 0 
      ) {
        if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          console.log("fff");
          this.pageSetting = false;
          this.pageBuilding = false;
          this.pageHome = true;
        } else {
          this.pageSetting = true;
          this.pageBuilding = false;
          this.pageHome = false;
        }
      } else {
        this.pageSetting = false;
        this.pageBuilding = false;
      }
      if (
        this.path.search("Events") === 0 ||
        this.path.search("Keywords") === 0 ||
        this.path.search("LineBuildings") === 0 ||
        this.path.search("Map") === 0 ||
        this.path.search("Addresses") === 0 ||
        this.path.search("Configs") === 0
      ) {
        this.pageHome = false;
        this.pageBuilding = true;
      } else {
        this.pageBuilding = false;
      }
    },
  },
};
</script>
<style></style>