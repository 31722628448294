<template>
  <div v-resize="onResize" v-if="getCompanies">
    <v-container fluid>
      <v-card class="ma-1">
        <v-card-title>
          <v-row align="center">
            <v-col cols="12" lg="4" md="4" sm="3"> Report </v-col>
            <v-col align="end">
              <v-row align="center">
                <v-col cols="12" lg="3" md="2" sm="3">
                  <v-select
                    v-if="getAccess.role == 'Superadmin'"
                    :items="getCompanies"
                    item-text="name"
                    item-value="_id"
                    name="company"
                    label="Select Company"
                    @change="changeCompany(datasearch.company)"
                    v-model="datasearch.company"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="3" md="2" sm="3">
                  <v-select
                    :items="getName"
                    item-value="_id"
                    item-text="buildingName"
                    label="Select Building"
                    v-model="datasearch.id"
                  ></v-select>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Select Date range"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      range
                      color="#D63636"
                      scrollable
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col align="end" cols="12" lg="2" md="2" sm="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#D63636"
                        fab
                        small
                        dark
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="searchDate()"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                    </template>
                    <span>Search</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="getReports"
          :items-per-page="5"
          :loading="loading"
          loading-text="Loading... Please wait"
        ></v-data-table>
        <div
          v-if="isMobile"
          class="
            table-footer-prepend-mobile
            d-flex
            pl-2
            align-center
            justify-center
          "
        >
          <v-btn color="error">
            <download-csv
              :fields="row"
              :data="getExport"
              name="report_firealarm.csv"
              encoding="utf-8"
            >
              Download Excel
            </download-csv>
          </v-btn>
        </div>
        <div v-else class="table-footer-prepend d-flex pl-2 align-center">
          <v-btn color="error">
            <download-csv
              :fields="row"
              :data="getExport"
              name="report_firealarm.csv"
              encoding="utf-8"
            >
              Download Excel
            </download-csv>
          </v-btn>
        </div> </v-card
      ><v-snackbar v-model="showResult" :timeout="2000" top right>
        {{ result }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import JsonCSV from "vue-json-csv";
export default {
  components: {
    JsonCSV,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getReports() {
      return this.$store.getters.reportsBuilding;
    },
    getExport() {
      return this.$store.getters.reportExport;
    },
    getName() {
      let newFirstElement = { buildingName: "All", _id: "" };
      let array = this.$store.getters.namesBuilding;
      let newArray = [newFirstElement].concat(array); // [ 4, 3, 2, 1 ]
      return newArray;
    },
    computedDateFormatted() {
      return this.formatDate(this.dates);
    },
    getCompanies() {
      let newFirstElement = { name: "All", _id: "" };
      let array = this.$store.getters.allCompanies;
      console.log(array);
      let newArray = [newFirstElement].concat(array); // [ 4, 3, 2, 1 ]
      console.log(newArray);
      return newArray;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role === "Superadmin") {
      this.headers.splice(3, 0, {
        text: "Company",
        value: "company.name",
        sortable: true,
        width: "10%",
      });
      this.columns.Company = "company.name";
      this.loadReports();
    } else {
      this.loadReports();
    }
  },
  data() {
    return {
      loading: false,
      showResult: false,
      result: "",
      menu: false,
      dialog: false,
      isMobile: false,
      dates: [],
      datasearch: {
        id: "",
        eventStart: "",
        eventEnd: "",
        company: "",
      },
      headers: [
        {
          text: "Date Time Start",
          align: "start",
          sortable: false,
          value: "dateTimeStart",
          width: "12%",
        },
        { text: "Date Time End", value: "dateTimeEnd", width: "12%" },
        { text: "Building", value: "building", width: "10%" },
        { text: "Type", value: "type", width: "10%" },

        { text: "Description", value: "description", width: "40%" },
      ],
      row: [
        "Date Time Start",
        "Date Time End",
        "Building",
        "Type",
        "Description",
      ],
      columns: {
        dateTimeStart: {
          title: "Date Time Start",
        },
        dateTimeEnd: {
          title: "Date Time End",
        },
        building: {
          title: "Building",
        },
        type: {
          title: "Type",
        },
        description: {
          title: "Description",
        },
      },
    };
  },
  methods: {
    onResize() {
      this.isMobile =
        this.$device.mobile || this.$device.ios || this.$device.android;
    },
    loadReports() {
      if (this.getAccess.role === "Superadmin") {
        this.$store.dispatch("getBuildingsWithCompany", this.datasearch.id);
        this.$store.dispatch("getCompanies");
      } else {
        this.$store.dispatch("getBuildingsWithCompany", this.getAccess.company);
      }
    },
    changeCompany(id) {
      this.$store.dispatch("getBuildingsWithCompany", id).then(
        () => {
          //console.log(this.getCompanyWithDepartment)
        },
        (error) => {
          if (error.response != undefined) {
            this.result = error.response.data.error.message;
          } else {
            this.result = error.message;
          }
          this.color = "red";
          this.showResult = true;
        }
      );
    },
    cancel() {
      this.menu = false;
      this.dates = null;
    },
    searchDate() {
      this.loading = true;
      if (this.dates != "") {
        if (this.dates.length === 2) {
          const [yearstart, monthstart, daystart] = this.dates[0].split("-");
          const [yearend, monthend, dayend] = this.dates[1].split("-");
          const data = [this.dates[0], this.dates[1]];
          if (yearstart > yearend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (monthstart > monthend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (daystart > dayend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }
          (this.datasearch.eventStart = this.parseDate(this.dates[0])),
            (this.datasearch.eventEnd = this.parseDate(this.dates[1])),
            this.$store
              .dispatch("getReportBySearch", this.datasearch)
              .then(() => {
                this.loading = false;
              });
          this.$store.dispatch("getReportExport", this.datasearch);
        } else {
          this.showResult = true;
          this.result = "Please choose date end.";
        }
      } else {
        this.$store.dispatch("getReportBySearch", this.datasearch).then(() => {
          this.loading = false;
        });
        this.$store.dispatch("getReportExport", this.datasearch);
      }
    },
    formatDate(dates) {
      if (!dates) return null;
      const dateFormatted = [];
      for (let i = 0; i < dates.length; i++) {
        dateFormatted[i] = this.parseDate(dates[i]);
      }
      if (dateFormatted.length === 2) {
        const [yearstart, monthstart, daystart] = dateFormatted[0].split("/");
        const [yearend, monthend, dayend] = dateFormatted[1].split("/");
        const data = [dateFormatted[0], dateFormatted[1]];
        if (yearstart > yearend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (monthstart > monthend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (daystart > dayend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        }
      } else if (dateFormatted.length === 1) {
        this.showResult = true;
        this.result = "Please choose date end.";
      }
      return dateFormatted.join(" - ");
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
};
</script>
<style>
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
.table-footer-prepend-mobile {
  margin-top: -20px;
  height: 116px;
}
</style>
