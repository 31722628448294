import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "users/";
import authHeader from "../services/auth-header";
export const accounts = {
  state: {
    users: [],
  },
  getters: {
    allUsers: (state) => state.users,
  },
  mutations: {
    getUsers(state, users) {
      state.users = users;
    },
    createUsers(state, user) {
      state.users.unshift(user);
    },
    updateUser(state, user) {
      //console.log(user);
      const index = state.users.findIndex((u) => u._id === user._id);
      state.users.splice(index, 1, user);
    },
    deleteUser(state, id) {
      const index = state.users.findIndex((u) => u._id === id);
      state.users.splice(index, 1);
    },
  },
  actions: {
    async getUsers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("getUsers", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createUsers(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            company: data.company._id,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("createUsers", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return "Error: Sorry,that username already exists!";
        });
    },
    deleteUser(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
          context.commit("deleteUser", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editUsers(constext, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
            username: data.username,
            role: data.role,
            company: data.company._id,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data);
          if (res.data.message != "Sorry Username Is Already Exists") {
            constext.commit("updateUser", data);
          }
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error;
        });
    },
    resetPassword(context, data) {
      //console.log(data);
      return axios
        .put(
          apiURL + "resetPassword/" + data.id,
          {
            id: data,
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Reset password successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
