import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Reports from "../views/Reports.vue";
import Accounts from "../views/Accounts.vue";
import Companies from "../views/Companies.vue";
import Buildings from "../views/Buildings.vue";
import Events from "../views/Events.vue";
import LineBuildings from "../views/LineBuildings.vue";
import LineCompanies from "../views/LineCompanies.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Keywords from "../views/Keywords.vue";
import Map from "../views/Map.vue";
import Config from "../views/Config.vue";
import RealTime from "../views/RealTime";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/building/events/:id",
    name: "Events",
    component: Events,
  },
  {
    path: "/building/lines",
    name: "LineBuildings",
    component: LineBuildings,
  },
  {
    path: "/lines",
    name: "LineCompanies",
    component: LineCompanies,
  },
  {
    path: "/building/map",
    name: "Map",
    component: Map,
  },
  {
    path: "/building/keywords",
    name: "Keywords",
    component: Keywords,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
  },
  {
    path: "/buildings",
    name: "Buildings",
    component: Buildings,
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/building/configs",
    name: "Configs",
    component: Config,
  },
  {
    path: "/building/realtime",
    name: "RealTime",
    component: RealTime,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
