import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
import Notifications from "vue-notification";
import VueDragResize from "vue-drag-resize";

Vue.component("vue-drag-resize", VueDragResize);
import VueSocketIO from "vue-socket.io";
const socketURL = process.env.VUE_APP_SOCKET_URL;

import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll);
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import VueZoomer from "vue-zoomer";

Vue.use(VueZoomer);
Vue.component(VueCropper);
import device from "vue-device-detector";
Vue.use(device);
Vue.use(
  new VueSocketIO({
    //debug: true,
    connection: socketURL,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.use(Notifications);
Vue.use(Simplert, {
  disableOverlayClick: true,
});
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
import { faLine } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(faLine);
//excel
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";
import JsonCSV from "vue-json-csv";

Vue.component("downloadCsv", JsonCSV);
// 2. Install with custom options (defaults shown)
Vue.use(VueCustomTooltip, {
  name: "VueCustomTooltip",
  color: "#000",
  background: "#fff",
  borderRadius: 100,
  fontWeight: 400,
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
