import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "maps/";
import authHeader from "../services/auth-header";
export const maps = {
  state: {
    maps: [],
    map: [],
  },
  getters: {
    allMap: (state) => state.maps,
    oneMap: (state) => state.map,
  },
  mutations: {
    setMaps(state, maps) {
      state.maps = maps;
    },
    setMap(state, map) {
      state.map = map;
    },
     createMap(state, map) {
      state.maps.push(map);
    },
    updateMap(state, map) {
      const index = state.maps.findIndex((u) => u.id === map.id);
      state.maps.splice(index, 1, map);
    },
    deleteMap(state, id) {
      const index = state.maps.findIndex((c) => c.id === id);
      state.maps.splice(index, 1);
    },
  },
  actions: {
    async getMaps(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("setMaps", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async getOneMap(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setMap", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    createMap({ commit }, map) {
      console.log(map);
      return axios
        .post(apiURL, map, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("createMap", res.data.data);
          commit("clearAddress");
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return Promise.reject(error);
        });
    },
    updateMap({ commit }, map) {
      return axios
        .put(apiURL + map.id, map, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("updateMap", map);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return Promise.reject(error);
        });
    },
    deleteMap({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("deleteMap", id);
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
  },
};
