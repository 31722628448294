<template>
  <v-container fluid>
    <v-card class="ma-1">
      <v-card-title>
        Building
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getBuildings"
        :items-per-page="5"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="getAccess.username === 'pmAdmin'"
                @click="resetPass(item.id)"
                class="amber elevation-5 mr-2"
                icon
                ><v-icon>mdi-lock-reset</v-icon></v-btn
              >
            </template>
            <span>Reset Password</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="changePass(item.id)"
                class="orange elevation-5 mr-2"
                icon
                ><v-icon>mdi-key</v-icon></v-btn
              >
            </template>
            <span>Change Password</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="editBuildingData(item)"
                class="success elevation-5 mr-2"
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="error elevation-5"
                @click="delBuildingData(item.id)"
                icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.events`]="{ item }">
          <v-col cols="12" class="mt-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'Events', params: { id: item.id, data: item } }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    small
                    color="#e69191"
                    style="
                      border-style: solid;
                      border-right: 0px;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    "
                    width="70px"
                  >
                    <v-icon dark small>mdi-bell</v-icon>
                  </v-btn></router-link
                > </template
              ><span>Events</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'Map', params: { data: item } }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="getAccess.role != 'User'"
                    dark
                    small
                    color="#f2c994"
                    width="70px"
                    style="
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                  >
                    <v-icon dark small>mdi-map-marker-radius</v-icon>
                  </v-btn>
                </router-link>
              </template>
              <span>Maps</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{ name: 'Keywords', params: { data: item } }"
                  style="color: inherit; text-decoration: none"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="getAccess.role == 'Superadmin'"
                    dark
                    small
                    color="#f0ea81"
                    width="70px"
                    style="
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                  >
                    <v-icon dark small> mdi-clipboard-text </v-icon>
                  </v-btn>
                </router-link>
              </template>
              <span>Keywords</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'LineBuildings', params: { data: item } }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="getAccess.role != 'User'"
                    dark
                    small
                    color="#93cf82"
                    width="70px"
                    style="
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                  >
                    <font-awesome-icon
                      icon="fa-brands fa-line fa-sm"
                      style="font-size: 20px"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Lines</span>
            </v-tooltip>
          </v-col>
        </template>
      </v-data-table>
      <v-btn
        style="position: fixed; bottom: 25px; right: 0px"
        class="ma-10"
        fixed
        fab
        color="#D63636"
        dark
        absolute
        bottom
        right
        dense
        floating
        @click="form = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-dialog v-model="form" persistent max-width="600px">
        <template>
          <v-card>
            <v-card-title
              ><span class="text-h4">Create Building</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addBuilding()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="name"
                    label="Name*"
                    v-model="buildingData.buildingName"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    name="macAddress"
                    label="Mac Address*"
                    v-model="buildingData.macAddress"
                    :rules="[(v) => !!v || 'Mac Address is required']"
                    required
                  ></v-text-field>
                  <v-select
                    :items="getCompanies"
                    :item-text="'name'"
                    :item-value="'_id'"
                    name="company"
                    label="Company*"
                    v-model="buildingData.companyID"
                    required
                  ></v-select>
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                    "
                    name="password"
                    label="Password*"
                    v-model="buildingData.passwordBuilding"
                    @click:append="hidePassword = !hidePassword"
                    :rules="[(v) => !!v || 'Password is required']"
                    required
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn id="btnsave" text @click="addBuilding()"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="600px">
        <template>
          <v-card>
            <v-card-title
              ><span class="text-h5">Edit Profile</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editBuilding()"
                ref="buildingDataEdit"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="name"
                    label="Name"
                    v-model="buildingDataEdit.buildingName"
                    required
                  ></v-text-field>
                  <v-text-field
                    :disabled="getAccess.username != 'pmAdmin'"
                    name="macAddress"
                    label="Mac Address"
                    v-model="buildingDataEdit.macAddress"
                    :rules="[(v) => !!v || 'Mac Address is required']"
                    required
                    @keypress="isLetter($event)"
                  ></v-text-field>
                  <v-select
                    :items="getCompanies"
                    :item-text="'name'"
                    :item-value="'_id'"
                    name="company"
                    label="Company"
                    v-model="buildingDataEdit.company._id"
                    required
                  ></v-select>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn
                id="btnsave"
                text
                @click="
                  editBuilding();
                  closeDlg();
                "
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="confirmDelete" max-width="300">
        <v-card>
          <v-card-title primary-title> Confirm Delete </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this Building ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delBuilding()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPass" max-width="400px">
        <v-card>
          <v-card-title primary-title v-if="reset == true">
            Reset password</v-card-title
          >
          <v-card-title primary-title v-else> Change password</v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="resetPasswords()"
              lazy-validation
              enctype="multipart/form-data"
              autocomplete="off"
            >
              <v-text-field
                v-if="reset == false"
                :type="hideOldPassword ? 'password' : 'text'"
                :append-icon="
                  hideOldPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="password"
                label="Old Password*"
                hide-details="auto"
                v-model="password.old"
                @click:append="hideOldPassword = !hideOldPassword"
                :rules="[(v) => !!v || 'New Password is required']"
                required
                @keypress="isLetter($event)"
              ></v-text-field>
              <v-text-field
                :type="hideNewPassword ? 'password' : 'text'"
                :append-icon="
                  hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="password"
                label="New Password*"
                hide-details="auto"
                v-model="password.new"
                @click:append="hideNewPassword = !hideNewPassword"
                :rules="[(v) => !!v || 'New Password is required']"
                required
                @keypress="isLetter($event)"
              ></v-text-field>
              <v-text-field
                v-if="reset == true"
                :type="hideConfirmPassword ? 'password' : 'text'"
                :append-icon="
                  hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                "
                name="password"
                label="Confirm Password*"
                hide-details="auto"
                v-model="password.confirm"
                @click:append="hideConfirmPassword = !hideConfirmPassword"
                :rules="[(v) => !!v || 'Confirm Password is required']"
                required
                @keypress="isLetter($event)"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>
            <v-btn id="btnsave" text @click="savePassword()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "building",
  data: () => ({
    reset: false,
    dialogPass: false,
    hidePassword: true,
    hideNewPassword: true,
    hideOldPassword: true,
    hideConfirmPassword: true,
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    headers: [
      { text: "Name", value: "buildingName", sortable: true },
      { text: "macAddress", value: "macAddress", sortable: true },
      { text: "Company", value: "company.name", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
      { text: "", value: "events", sortable: false, align: "center" },
    ],
    password: {
      id: "",
      old: "",
      new: "",
      confirm: "",
    },
    buildingData: {
      buildingName: "",
      companyName: "",
      companyID: "",
      macAddress: "",
      passwordBuilding: "",
    },
    buildingDataEdit: {
      buildingName: "",
      company: {
        _id: "",
        name: "",
      },
      macAddress: "",
      passwordBuilding: "",
    },
  }),
  computed: {
    getBuildings() {
      return this.$store.getters.getAllBuildings;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role === "Superadmin") {
      this.loadBuilding();
    } else {
      this.$router.push("/");
    }
    console.log(this.getBuildings);
    console.log("this.getBuildings");
  },
  methods: {
    resetPass(id) {
      this.reset = true;
      this.dialogPass = true;
      this.password.id = id;
    },
    changePass(id) {
      this.reset = false;
      this.dialogPass = true;
      this.password.id = id;
    },
    savePassword() {
      if (this.reset == true) {
        this.resetPasswords();
      } else {
        this.changePassword();
      }
    },
    resetPasswords() {
      if (this.password.new == "" || this.password.confirm == "") {
        this.text = "Error! Please fill out this form.";
        this.color = "red";
        this.snackbar = true;
      } else {
        if (this.password.new === this.password.confirm) {
          this.$store
            .dispatch("resetPasswordBuilding", this.password)
            .then(() => {
              this.dialogPass = false;
              this.closeDlg();
              this.color = "success";
              this.text = "Reset Password Complete.";
              this.resetPW = false;
              this.snackbar = true;
            })
            .catch((error) => {
              this.color = "error";
              this.text = " Error! You cannot reset password drill.";
              this.snackbar = true;
            });
        } else {
          this.color = "error";
          this.text = " Error! Passwords do not match!";
          this.snackbar = true;
        }
      }
    },
    changePassword() {
      console.log(this.password);
      if (this.password.new == "" || this.password.old == "") {
        this.text = "Error! Please fill out this form.";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store.dispatch("changePasswordBuilding", this.password).then(
          () => {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Password drill has been changed!";
            this.snackbar = true;
          },
          () => {
            this.text = "Error! Old password drill is incorrect!";
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    loadBuilding() {
      this.$store.dispatch("getAllBuildings");
      this.$store.dispatch("getCompanies");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.buildingData.buildingName = "";
      this.buildingData.macAddress = "";
      this.buildingData.companyID = "";
      this.buildingData.passwordBuilding = "";
      this.dialogPass = false;
      this.password.new = "";
      this.password.old = "";
      this.password.confirm = "";
    },
    delBuildingData(id) {
      console.log(id);
      this.confirmDelete = true;
      this.buildingDelId = id;
    },
    addBuilding() {
      if (
        this.buildingData.buildingName === "" ||
        this.buildingData.macAddress === "" ||
        this.buildingData.passwordBuilding == ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create an Building.";
      } else {
        this.$store
          .dispatch("createBuildings", this.buildingData)
          .then((res) => {
            if (res.status === 201 || res.status == 200) {
              this.closeDlg();

              this.color = "success";
              this.text = "Building has been successfully saved.";
              this.snackbar = true;
            } else {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          });
      }
    },
    delBuilding() {
      this.$store
        .dispatch("deleteBuildings", this.buildingDelId)
        .then(() => {
          this.closeDlg();

          this.color = "success";
          this.text = "Deleted! Building has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this Building.";
          this.snackbar = true;
        });
    },
    editBuildingData(data) {
      console.log(data);
      console.log("data");
      //console.log(this.buildingDataEdit);
      // console.log("this.buildingDataEdit");
      this.editform = true;
      this.buildingDataEdit = Object.assign({}, data);
      console.log(this.buildingDataEdit);
      /*  this.buildingDataEdit.id = data.id;
      this.buildingDataEdit.buildingName = data.buildingName;
      this.buildingDataEdit.company = data.company;
      this.buildingDataEdit.macAddress = data.macAddress; */
    },
    editBuilding() {
      console.log(this.buildingDataEdit);
      console.log("this.buildingDataEdit");
      const index = this.getCompanies.findIndex(
        (u) => u._id === this.buildingDataEdit.company._id
      );
      this.buildingDataEdit.company.name = this.getCompanies[index].name;
      this.$store
        .dispatch("editBuildings", this.buildingDataEdit)
        .then((res) => {
          console.log(res);

          if (res.status === 201 || res.status == 200) {
            console.log(res);

            this.closeDlg();
            this.color = "success";
            this.text = "Success! Building has been edited.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this Building.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>
