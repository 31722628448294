import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "companies/";
import authHeader from "../services/auth-header";
export const companies = {
  state: {
    companies: [],
  },
  getters: {
    allCompanies: (state) => state.companies,
  },
  mutations: {
    setCompanies(state, company) {
      state.companies = company;
    },
    createCompany(state, company) {
      state.companies.unshift(company);
    },
    updateCompany(state, company) {
      const index = state.companies.findIndex((u) => u._id === company._id);
      state.companies.splice(index, 1, company);
    },
    deleteCompany(state, id) {
      const index = state.companies.findIndex((u) => u._id === id);
      state.companies.splice(index, 1);
    },
  },
  actions: {
    async getCompanies({commit}) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          commit("setCompanies", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCompanies({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("createCompany", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteCompanies({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          commit("deleteCompany", id);
          console.log("Delete is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editCompanies({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          commit("updateCompany", data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
};
