import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "buildings/";
import authHeader from "../services/auth-header";
export const buildings = {
  state: {
    building: { id: null },
    buildings: {
      building: [],
      sound: false,
    },
    namesAll: [],
    buildingsAll: [],
  },
  mutations: {
    getOneBuilding(state, building) {
      state.building = building;
    },
    getBuildings(state, data) {
      console.log(data);
      state.buildings.sound = data.sound;
      for (let i = 0; i < data.building.length; i++) {
        state.buildings.building.push(data.building[i]);
       
      }
    },
    getAllBuildings(state, buildingsAll) {
      state.buildingsAll = buildingsAll;
    },
    getBuildingsWithCompany(state, name) {
      state.namesAll = name;
    },
    createBuildings(state, building) {
      building.id = building._id;
      state.buildingsAll.unshift(building);
      state.buildings.building.unshift(building);
    },
    updateBuilding(state, building) {
      console.log(state.buildingsAll);
      const index = state.buildings.building.findIndex(
        (u) => u.id == building.id
      );
      const indexAll = state.buildingsAll.findIndex((u) => u.id == building.id);

      if (index > -1) {
        state.buildings.building.splice(index, 1, building);
      }
      if (indexAll > -1) {
        state.buildingsAll.splice(index, 1, building);
      }
    },
    deleteBuilding(state, id) {
      console.log(id);
      const indexAll = state.buildingsAll.findIndex((u) => u.id == id);
      const index = state.buildings.building.findIndex((u) => u.id == id);
      if (index > -1) {
        state.buildings.building.splice(index, 1);
      }
      if (indexAll > -1) {
        state.buildingsAll.splice(indexAll, 1);
      }
    },
    updateAck(state, data) {
      if (state.building.id == data.building) {
        state.building.acknowledge = data.acknowledge;
      }
    },
    updateReset(state, data) {
      console.log(state.building);
      console.log(data);
      if (state.building.id == data.building) {
        state.building.reset = data.reset;
        console.log(state.building);
      }
    },
    updateSilence(state, data) {
      if (state.building.id == data.building) {
        state.building.silence = data.silence;
      }
    },
    updateDrll(state, data) {
      if (state.building.id == data.building) {
        state.building.drll = data.drll;
      }
    },
    updateSound(state, data) {
      if (state.building.id == data.building) {
        state.building.sound = data.sound;
      }
      const index = state.buildings.building.findIndex(
        (u) => u.id == data.building
      );
      if (index > -1) {
        state.buildings.building[index].sound = data.sound;
        const sound = state.buildings.building.findIndex(
          (u) => u.sound == true
        );
        console.log(index)
        if (sound > -1) {
          state.buildings.sound = true;
        } else {
          state.buildings.sound = false;
        }
      }
    },
    updateIO(state, data) {
      console.log(data);
      const index = state.buildings.building.findIndex(
        (u) => u.id == data.building
      );
      if (index > -1) {
        state.buildings.building[index].io = data.io;
      }
    },
    updateGate(state, data) {
      const index = state.buildings.building.findIndex(
        (u) => u.id == data.building
      );
      if (index > -1) {
        state.buildings.building[index].status = data.status;
      }
    },
    stopSound(state, id) {
      console.log(id);
      console.log(state.building);
      if (state.building.id == id) {
        state.building.sound = false
      }
      const index = state.buildings.building.findIndex(
        (u) => u.id == id
      );
      if (index > -1) {
        state.buildings.building[index].sound = false;
        const indexAll = state.buildings.building.findIndex(
          (u) => u.sound == true
        );
        if (indexAll > -1) {
          state.buildings.sound = true;
        } else {
          state.buildings.sound = false;
        }
      }
    },
  },
  getters: {
    getBuildings: (state) => state.buildings,
    namesBuilding: (state) => state.namesAll,
    getAllBuildings: (state) => state.buildingsAll,
    oneBuilding: (state) => state.building,
  },
  actions: {
    getOneBuilding({ commit }, id) {
      console.log(id)
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data[0]);
          commit("getOneBuilding", res.data.data[0]);
          return res.data.data[0];
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    getBuildings({ commit }, data) {
      return axios
        .post(
          apiURL,
          { lastNumber: data.last, length: data.length },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("getBuildings", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    getBuildingsWithCompany({ commit }, id) {
      console.log(id);
      return axios
        .post(apiURL + "selecter", { company: id }, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          commit("getBuildingsWithCompany", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error.response)
          return Promise.reject(error);
        });
    },
    async getAllBuildings({ commit }) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          //console.log('ssss');
          commit("getAllBuildings", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createBuildings({ commit }, data) {
      //console.log(apiURL + "insert");
      return axios
        .post(
          apiURL + "insert",
          {
            buildingName: data.buildingName,
            company: data.companyID,
            macAddress: data.macAddress,
            passwordBuilding: data.passwordBuilding,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("createBuildings", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteBuildings({ commit }, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          commit("deleteBuilding", id);
          console.log("Delete is successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editBuildings({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + data.id,
          {
            buildingName: data.buildingName,
            company: data.company._id,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("updateBuilding", data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    resetPasswordBuilding(context, payload) {
      console.log(payload);
      return axios
        .put(
          apiURL + "resetPassword/" + payload.id,
          {
            id: payload,
            newPassword: payload.new,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Reset password successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePasswordBuilding(context, payload) {
      console.log(payload);
      return axios.put(
        apiURL + "changePassword/" + payload.id,
        {
          newPassword: payload.new,
          password: payload.old,
        },
        { headers: authHeader() }
      );
    },
  },
};
