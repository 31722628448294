<template>
  <div v-if="getAccess">
    <v-container v-resize="onResize" fluid v-if="getEvents.data" class="mt-3">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card style="position: relative; height: 100%">
            <v-row align="center">
              <v-col cols="10">
                <v-card-text class="my-1">
                  <span class="font-weight-bold" style="font-size: 250%">
                    <v-icon class="fa fa-fire"></v-icon>
                    {{ getBuilding.buildingName }}
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row align-self="center" justify="center" no-gutters>
              <div style="width: 110px">
                <h4 class="justify-center text-center" v-if="!history">
                  {{ showvalue(getEvents, "Fire Alarm") }}
                </h4>
                <h4 class="justify-center text-center" v-else>
                  {{ showvalue(getHistorys, "Fire Alarm") }}
                </h4>
                <v-btn
                  width="100px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  :style="checkFilter('Fire Alarm')"
                  @click="filterType('Fire Alarm')"
                >
                  Fire Alarm
                </v-btn>
              </div>
              <div style="width: 110px">
                <h4 class="justify-center text-center" v-if="!history">
                  {{ showvalue(getEvents, "Trouble") }}
                </h4>
                <h4 class="justify-center text-center" v-else>
                  {{ showvalue(getHistorys, "Trouble") }}
                </h4>
                <v-btn
                  width="100px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  :style="checkFilter('Trouble')"
                  @click="filterType('Trouble')"
                >
                  Trouble
                </v-btn>
              </div>
              <div style="width: 110px">
                <h4 class="justify-center text-center" v-if="!history">
                  {{ showvalue(getEvents, "Bell") }}
                </h4>
                <h4 class="justify-center text-center" v-else>
                  {{ showvalue(getHistorys, "Bell") }}
                </h4>
                <v-btn
                  width="100px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  :style="checkFilter('Bell')"
                  @click="filterType('Bell')"
                >
                  Bell
                </v-btn>
              </div>

              <div style="width: 110px">
                <h4 class="justify-center text-center" v-if="!history">
                  {{ showvalue(getEvents, "Disable") }}
                </h4>
                <h4 class="justify-center text-center" v-else>
                  {{ showvalue(getHistorys, "Disable") }}
                </h4>
                <v-btn
                  width="100px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  :style="checkFilter('Disable')"
                  @click="filterType('Disable')"
                >
                  Disable
                </v-btn>
              </div>
              <div style="width: 110px">
                <h4 class="justify-center text-center" v-if="!history">
                  {{ showvalue(getEvents, "Activity") }}
                </h4>
                <h4 class="justify-center text-center" v-else>
                  {{ showvalue(getHistorys, "Activity") }}
                </h4>
                <v-btn
                  width="100px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  :style="checkFilter('Activity')"
                  @click="filterType('Activity')"
                >
                  Activity
                </v-btn>
              </div>
              <div
                style="width: 100px; align-self: end"
                v-if="this.searchTypeEvent.length != 5"
              >
                <v-btn
                  width="90px"
                  dark
                  rounded
                  small
                  class="ma-1"
                  style="background-color: #00a0b0"
                  @click="filterType('All')"
                >
                  Select All
                </v-btn>
              </div>
            </v-row>

            <v-row>
              <v-col cols="12" class="ml-0">
                <v-row>
                  <v-col lg="10" md="6" sm="10">
                    <v-tabs
                      v-model="tab"
                      background-color="transparent"
                      color="red"
                      grow
                      class="ml-0"
                    >
                      <v-tab @click="sumevents()">
                        <v-icon left>mdi-bell-ring</v-icon> event
                      </v-tab>
                      <v-tab @click="sumhistorys()">
                        <v-icon left>mdi-history</v-icon>history
                      </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col
                    lg="2"
                    md="4"
                    sm="2"
                    align="center"
                    v-if="
                      this.history == false && getAccess.role == 'Superadmin'
                    "
                  >
                    <v-btn
                      @click="clear()"
                      small
                      color="#00A0B0"
                      class="white--text mr-3 ml-n3 my-2"
                    >
                      clear
                    </v-btn>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-tabs-items v-model="tab" class="pa-0">
                    <v-tab-item style="height: 45vh; overflow: scroll">
                      <v-col
                        v-if="getEvents.data.length < 1"
                        justify="center"
                        align="center"
                      >
                        No Events
                      </v-col>
                      <v-col v-else v-for="(item, index) in getEvents.data">
                        <v-card class="ma-1" :style="colorCard(item.type)">
                          <v-row justify="center" align="center">
                            <v-col
                              cols="3"
                              lg="2"
                              md="2"
                              sm="3"
                              justify="center"
                              align="center"
                            >
                              <v-icon dark x-large class="ml-3">
                                {{ iconshow(item.type) }}
                              </v-icon>
                            </v-col>
                            <v-col style="background-color: #fff">
                              <h4>{{ item.description }}</h4>
                              <span>{{ item.type }}</span>
                              <h5 v-if="item.dateTimeStart">
                                {{ item.dateTimeStart }}
                              </h5>
                              <h5 v-else>{{ item.eventStart }}</h5>
                            </v-col>
                          </v-row>
                        </v-card> </v-col
                      ><infinite-loading
                        @infinite="infiniteEvent"
                        v-if="loadEvent"
                      ></infinite-loading>
                    </v-tab-item>
                    <v-tab-item
                      style="height: 45vh; overflow: scroll"
                      v-if="getHistorys.data"
                    >
                      <v-col
                        v-if="getHistorys.data.length < 1"
                        justify="center"
                        align="center"
                      >
                        No History
                      </v-col>
                      <v-col v-else v-for="(item, index) in getHistorys.data">
                        <v-card class="ma-1" :style="colorCard(item.type)">
                          <v-row justify="center" align="center">
                            <v-col
                              cols="3"
                              lg="2"
                              md="2"
                              sm="3"
                              justify="center"
                              align="center"
                            >
                              <v-icon dark x-large class="ml-3">
                                {{ iconshow(item.type) }}
                              </v-icon>
                            </v-col>
                            <v-col style="background-color: #fff">
                              <h4>{{ item.description }}</h4>
                              <span>{{ item.type }}</span>
                              <h5 v-if="item.dateTimeStart">
                                {{ item.dateTimeStart }}
                              </h5>
                              <h5 v-else>{{ item.eventStart }}</h5>
                            </v-col>
                          </v-row>
                        </v-card> </v-col
                      ><infinite-loading
                        @infinite="infiniteHis"
                        v-if="loadHis"
                      ></infinite-loading>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-col>
            </v-row>

            <v-snackbar
              v-model="dataSnack.showResult"
              :color="dataSnack.color"
              :timeout="2000"
              top
              right
            >
              {{ dataSnack.result }}
              <v-btn color="white" text @click="dataSnack.showResult = false">
                close
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card id="cardLayout" style="position: relative; height: 100%">
            <v-row align="center">
              <v-card-text class="mb-n2 mt-1 ml-3">
                <span class="font-weight-bold black--text text-h6">
                  Layout
                </span>
              </v-card-text>
            </v-row>

            <v-row justify="center" align="center">
              <div
                style="width: 80px"
                class="
                  pt-1
                  mx-1
                  text-center
                  grey
                  lighten-2
                  text-no-wrap
                  rounded
                "
              >
                <v-icon
                  :color="checkStatus(getBuilding.acknowledge)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="text-uppercase" style="letter-spacing: -1.5px">
                  acknowledge
                </h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="white--text flex-no-wrap justify-center mt-1 mx-0 mb-2"
                  style="padding: 1vw 1vw"
                  @click="setAcknowledge()"
                  :loading="setEvent.value"
                  :disabled="setEvent.value"
                >
                </v-btn>
              </div>
              <div
                style="width: 80px"
                v-if="getAccess.role != 'User'"
                class="
                  pt-1
                  mr-1
                  text-center
                  grey
                  lighten-2
                  text-no-wrap
                  rounded
                "
              >
                <v-icon
                  :color="checkStatus(getBuilding.silence)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="text-uppercase px-1">silence</h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="white--text flex-no-wrap justify-center mt-1 mx-0 mb-2"
                  style="padding: 1vw 1vw"
                  @click="setBell()"
                  :disabled="setEvent.value"
                  :loading="setEvent.value"
                >
                </v-btn>
              </div>
              <div
                style="width: 80px"
                v-if="getAccess.role != 'User'"
                class="
                  pt-1
                  mr-1
                  text-center text-no-wrap
                  rounded
                  grey
                  lighten-2
                "
              >
                <v-icon
                  :color="checkStatus(getBuilding.reset)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="px-1">RESET</h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="white--text flex-no-wrap justify-center mt-1 mx-0 mb-2"
                  @click="setReset()"
                  style="padding: 1vw 1vw"
                  :disabled="setEvent.value"
                  :loading="setEvent.value"
                >
                </v-btn>
              </div>
              <div
                style="width: 80px"
                class="
                  pt-1
                  mr-1
                  text-center text-no-wrap
                  rounded
                  grey
                  lighten-2
                "
                v-if="
                  getAccess.role == 'Superadmin' ||
                  getAccess.role == 'AdminDrill'
                "
              >
                <v-icon
                  :color="checkStatus(getBuilding.drll)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="px-1">DRILL</h5>
                <v-btn
                  small
                  width="20%"
                  color="#F5F5F5"
                  class="white--text flex-no-wrap justify-center mt-1 mx-0 mb-2"
                  @click="openDrill"
                  style="padding: 1vw 1vw"
                  :disabled="setEvent.value"
                  :loading="setEvent.value"
                >
                </v-btn>
              </div>
            </v-row>

            <v-row justify="center" v-if="getMaps.length > 0">
              <v-window v-model="onboarding" reverse touchless>
                <v-window-item v-for="n in getMaps.length">
                  <v-col justify="start" class="mx-1">
                    <v-btn-toggle dense>
                      <v-btn small icon @click="zoomIn(n - 1)">
                        <v-icon style="font-size: 15px">mdi-plus</v-icon>
                      </v-btn>

                      <v-btn small icon @click="zoomOut(n - 1)">
                        <v-icon style="font-size: 15px">mdi-minus</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="12" class="ml-1 mr-2 pr-5">
                    <v-zoomer
                      style="
                        position: relative;
                        height: 45vh;
                        max-width: 90vw;
                        overflow: scroll;
                      "
                      ref="zoomer"
                      :zoomed.sync="zoomed"
                      :max-scale="getScale"
                    >
                      <v-img
                        :id="getMaps[n - 1].id"
                        style="position: relative; width: 100vw; height: auto"
                        :src="getMaps[n - 1].photo"
                      >
                        <div class="justify-center text-center">
                          <v-progress-circular
                            indeterminate
                            color="red"
                            v-if="loadingMap"
                            style="position: relative; top: 120px"
                          ></v-progress-circular>
                        </div>

                        <div
                          v-for="event in showPoint"
                          v-if="isShowPoint"
                          style="position: relative"
                          :style="calPosition(event)"
                        >
                          <div v-if="event.type != 'Activity'">
                            <span
                              style="
                                top: -26px;
                                left: -25px;
                                z-index: 2;
                                position: absolute;
                                border-radius: 4px;
                                font-size: 12px;
                                background-color: azure;
                              "
                            >
                              {{ event.point.name }}
                            </span>
                            <div class="loading">
                              <span :style="colorCard(event.type)"></span>

                              <v-avatar
                                size="22"
                                :style="colorCard(event.type)"
                              >
                                <v-icon dark small> mdi-access-point </v-icon>
                              </v-avatar>
                            </div>
                          </div>
                        </div>
                      </v-img>
                    </v-zoomer>
                  </v-col>
                </v-window-item>
              </v-window>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="ml-1 mr-2 pr-5">
                <v-img
                  style="position: relative; height: 45vh; max-width: 90vw"
                  src="https://awarethai.com/api/images/nopic.png"
                ></v-img>
              </v-col>
            </v-row>

            <v-card-actions class="justify-space-between mt-n2 mb-3">
              <v-btn text @click="prev">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <h4 class="text-center" mandatory>
                {{ onboarding + 1 }} of {{ getMaps.length }}
              </h4>
              <v-btn text @click="next">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="370">
        <v-card>
          <v-card-title class="text-h5" v-if="openClear">
            Do You want to clear events?
          </v-card-title>
          <v-card-title class="text-h5" v-else>
            Do You want to set drill?
          </v-card-title>
          <v-card-text>
            <p>Please confirm password building before continuing.</p>
            <v-text-field
              :type="hidePassword ? 'password' : 'text'"
              :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'"
              name="password"
              label="Password*"
              :value="dataBuilding.passwordBuilding"
              @change="dataBuilding.passwordBuilding = $event"
              @click:append="hidePassword = !hidePassword"
              @keypress="isLetter($event)"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="setClear()"
              v-if="openClear"
            >
              Clear
            </v-btn>
            <v-btn color="green darken-1" text @click="setDrill()" v-else>
              set
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container v-else class="text-center">
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Cookies from "js-cookie";
export default {
  components: {
    InfiniteLoading,
  },
  computed: {
    getScale() {
      //console.log(this.$store.getters.getScale);
      return this.$store.getters.getScale;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getMaps() {
      return this.$store.getters.allMap;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getEvents() {
      return this.$store.getters.eventsBuilding;
    },
    getHistorys() {
      return this.$store.getters.reportBuilding;
    },
    getBuilding() {
      return this.$store.getters.oneBuilding;
    },
    loadEvent() {
      console.log(this.$store.getters.infiniteEvent);
      return this.$store.getters.infiniteEvent;
    },
    loadHis() {
      console.log(this.$store.getters.infiniteHis);
      return this.$store.getters.infiniteHis;
    },
    showPoint() {
      let data = [];
      let events = this.$store.getters.getAllPonit;
      const newArr = events.filter((object) => {
        return object.point != undefined;
      });
      let indexes = newArr
        .map((elm, idx) =>
          elm.point.map == this.getMaps[this.onboarding].id ? idx : ""
        )
        .filter(String);
      //console.log(indexes);
      for (let i = 0; i < indexes.length; i++) {
        if (newArr[indexes[i]].type != "Activity") {
          data.push(newArr[indexes[i]]);
        }
      }

      const unique = [...new Map(data.map((m) => [m.address, m])).values()];
      return unique;
    },
  },
  data() {
    return {
      eventSearch: false,
      hisSearch: false,
      loadingBtn: { value: false },
      loadingMap: true,
      setEvent: { value: false },
      openClear: true,
      hidePassword: true,
      searchTypeHis: ["Fire Alarm", "Trouble", "Bell", "Disable", "Activity"],
      searchTypeEvent: ["Fire Alarm", "Trouble", "Bell", "Disable", "Activity"],
      length: 3,
      onboarding: 0,
      imgLeft: 30,
      imgTop: 60,
      dialog: false,
      troubles: [],
      showResult: false,
      result: "",
      alert: null,
      silence: null,
      name: null,
      id: null,
      idCompany: null,
      tab: null,
      isMobile: false,
      history: false,
      dataBuilding: {
        id: null,
        status: null,
        buildingName: null,
        passwordBuilding: null,
      },
      data: {
        id: null,
        last: 0,
        length: 10,
      },
      dataSnack: {
        showResult: false,
        result: null,
        color: null,
      },
      items: ["event", "history"],
      text: "Lorem",
      map: {
        height: 0,
        width: 0,
        top: 0,
        left: 0,
      },
      isShowPoint: false,
      Hisheaders: [
        {
          text: "Date Time Start",
          sortable: false,
          value: "dateTimeStart",
          width: "16%",
        },
        {
          text: "Date Time End",
          value: "dateTimeEnd",
          sortable: false,
          width: "15%",
        },
        { text: "Type", value: "type", sortable: false, width: "15%" },
        { text: "Event", value: "event", sortable: false, width: "15%" },
        {
          text: "Description",
          value: "description",
          sortable: false,
          width: "35%",
        },
      ],
      headers: [
        {
          text: "Date Time Start",
          sortable: false,
          value: "dateTimeStart",
          width: "16%",
        },
        { text: "Type", value: "type", sortable: false, width: "15%" },
        { text: "Event", value: "event", sortable: false, width: "15%" },
        { text: "Description", value: "description", sortable: false },
      ],
      zoomed: false,
      imageAspectRatio: 1,
    };
  },
  beforeRouteLeave(to, from, next) {
    //console.log("ddd");
    this.sockets.unsubscribe("status");
    next();
  },
  beforeRouteUpdate(to, from, next) {
    //console.log(to);
    Cookies.set("idBuilding", from.params.id);
    next();
  },
  mounted() {
    //console.log(this.$route.params.id);
    Cookies.set("idBuilding", this.$route.params.id);
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.sockets.subscribe("status", (data) => {
        //console.log(data);
        if (this.id == data.building) {
          if (data.point == undefined) {
          } else {
            console.log(this.getMaps);
            if (data.type != "Activity") {
              const index = this.getMaps.findIndex(
                (c) => c.id == data.point.map
              );
              console.log(index);
              this.onboarding = index;
            }
          }
        } else {
        }
      });
    }
  },
  created() {
    this.$store.dispatch("auth/decode").then(() => {
      if (this.getAccess) {
        if (this.$route.params.data != null) {
          this.id = this.$route.params.id;
          this.data.id = this.$route.params.id;
          this.name = this.$route.params.data.buildingName;
          Cookies.set("dataBuilding", this.$route.params.data);
          Cookies.set("nameBuilding", this.$route.params.data.buildingName);
          Cookies.set("idBuilding", this.$route.params.id);
          Cookies.set("idCompany", this.$route.params.data.company._id);
          this.loadPage();
        } else if (this.$route.params.id) {
          Cookies.set("idBuilding", this.$route.params.id);
          //console.log(Cookies.get("idBuilding"));
          this.loadPage();
        }
      }
    });
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    closeDlg() {
      this.dialog = false;
      this.dataBuilding.passwordBuilding = null;
    },
    showvalue(data, type) {
      //console.log(data.type);
      const index = data.type.findIndex((d) => d.name == type);
      if (index > -1) {
        return data.type[index].value;
      } else {
        return 0;
      }
    },
    filterType(data) {
      if (this.tab == 0) {
        this.eventSearch = true;
        //this.$store.dispatch("loadingEvent");
        console.log(this.searchTypeEvent);
        this.$store.dispatch("clearEventForType");
        const index = this.searchTypeEvent.findIndex((type) => type == data);
        if (data == "All") {
          this.searchTypeEvent = [
            "Fire Alarm",
            "Trouble",
            "Bell",
            "Disable",
            "Activity",
          ];
        } else {
          if (this.searchTypeEvent.length == 5) {
            this.searchTypeEvent = [data];
          } else if (index > -1) {
            this.searchTypeEvent.splice(index, 1);
          } else if (index == -1) {
            this.searchTypeEvent.push(data);
          }
        }

        let dataFilter = {
          id: this.data.id,
          filter: this.searchTypeEvent,
          last: this.getEvents.data.length,
          length: 10,
        };
        this.$store.dispatch("getEventByType", dataFilter);
      } else {
        this.hisSearch = true;
        //this.$store.dispatch("loadingHis");
        this.$store.dispatch("clearHisForType");
        const index = this.searchTypeHis.findIndex((type) => type == data);
        if (this.searchTypeHis.length == 5) {
          this.searchTypeHis = [data];
        } else if (index > -1) {
          this.searchTypeHis.splice(index, 1);
        } else if (index == -1) {
          this.searchTypeHis.push(data);
        }
        let dataFilter = {
          id: this.data.id,
          filter: this.searchTypeHis,
          last: this.getHistorys.data.length,
          length: 10,
        };
        this.$store.dispatch("getReportByType", dataFilter);
      }
    },
    checkFilter(data) {
      if (this.tab == 0) {
        const index = this.searchTypeEvent.findIndex((type) => type == data);
        if (index > -1) {
          return this.colorCard(data);
        } else {
          return "background-color: grey";
        }
      } else {
        const index = this.searchTypeHis.findIndex((type) => type == data);
        if (index > -1) {
          return this.colorCard(data);
        } else {
          return "background-color: grey";
        }
      }
    },
    onImageLoad(e) {
      //console.log(e);
      const img = e.target;
      this.imageAspectRatio = img.naturalWidth / img.naturalHeight;
    },
    checkTrouble() {
      if (this.troubles.length > 0) {
        return "warning";
      } else {
        return "grey";
      }
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.getMaps.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.getMaps.length - 1 : this.onboarding - 1;
    },
    checkStatus(status) {
      if (status == true) {
        return "red darken-2";
      } else {
        return "grey";
      }
    },
    setBell() {
      let dataSnack = this.dataSnack;
      let dataBuilding = this.dataBuilding;
      let setEvent = this.setEvent;
      let loadingBtn = this.loadingBtn;
      dataBuilding.id = Cookies.get("idBuilding");
      dataBuilding.buildingName = Cookies.get("nameBuilding");
      let confirmFn = function () {
        dataBuilding.status = true;
        try {
          this.$store
            .dispatch("setBell", dataBuilding)
            .then((res) => {
              if (res.status == 200) {
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Silence is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  loadingBtn.value = false;
                  setEvent.value = false;
                }, 3000);
              } else {
                //console.log("ddd");
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set silence.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set silence.";
        }
      };
      let closeFn = function () {
        dataBuilding.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setBell", dataBuilding)
            .then((res) => {
              if (res.status == 200) {
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Silence is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  setEvent.value = false;
                  loadingBtn.value = false;
                }, 3000);
              } else {
                //console.log("ddd");
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set silence.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set silence.";
        }
      };
      let obj = {
        message:
          '<span style="font-size: 150%; font-weight: bold;">Do You want to set silence?</span>',
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },
    setAcknowledge() {
      let dataSnack = this.dataSnack;
      let dataBuilding = this.dataBuilding;
      let setEvent = this.setEvent;
      let loadingBtn = this.loadingBtn;
      dataBuilding.id = Cookies.get("idBuilding");
      dataBuilding.buildingName = Cookies.get("nameBuilding");
      let confirmFn = function () {
        dataBuilding.status = true;
        try {
          this.$store
            .dispatch("setAcknowledge", dataBuilding)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Acknowledge is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  setEvent.value = false;
                  loadingBtn.value = false;
                }, 3000);
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set acknowledge.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set acknowledge.";
        }
      };
      let closeFn = function () {
        dataBuilding.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setAcknowledge", dataBuilding)
            .then((res) => {
              if (res.status == 200) {
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Acknowledge is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  setEvent.value = false;
                  loadingBtn.value = false;
                }, 3000);
              } else {
                //console.log("ddd");
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set acknowledge.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set acknowledge.";
        }
      };
      let obj = {
        message:
          '<span style="font-size: 150%; font-weight: bold;">Do You want to set acknowledge?</span>',
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },
    setReset() {
      let dataSnack = this.dataSnack;
      let dataBuilding = this.dataBuilding;
      let setEvent = this.setEvent;
      let loadingBtn = this.loadingBtn;
      dataBuilding.id = Cookies.get("idBuilding");
      dataBuilding.buildingName = Cookies.get("nameBuilding");
      let confirmFn = function () {
        dataBuilding.status = true;
        try {
          this.$store
            .dispatch("setReset", dataBuilding)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Reset is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  setEvent.value = false;
                  loadingBtn.value = false;
                }, 3000);
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set reset.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set reset.";
        }
      };
      let closeFn = function () {
        dataBuilding.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setReset", dataBuilding)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Reset is success.";
                setEvent.value = true;
                loadingBtn.value = true;
                setTimeout(() => {
                  setEvent.value = false;
                  loadingBtn.value = false;
                }, 3000);
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set reset.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set reset.";
        }
      };
      let obj = {
        type: "warning",
        message:
          '<span style="font-size: 150%; font-weight: bold;">Do You want to set reset?</span>',
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },
    setDrill() {
      console.log(this.dataBuilding.passwordBuilding);
      if (
        this.dataBuilding.passwordBuilding == "" ||
        this.dataBuilding.passwordBuilding == null
      ) {
        this.dataSnack.showResult = true;
        this.dataSnack.color = "error";
        this.dataSnack.result = "Please fill password building.";
      } else {
        this.dataBuilding.id = Cookies.get("idBuilding");
        this.dataBuilding.buildingName = Cookies.get("nameBuilding");

        this.$store
          .dispatch("setDrill", this.dataBuilding)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              this.closeDlg();
              this.dataSnack.color = "success";
              this.dataSnack.showResult = true;
              this.dataSnack.result = "Set Drill is success.";
              this.setEvent.value = true;

              setTimeout(() => {
                this.setEvent.value = false;
                this.setEvent.value = false;
              }, 3000);
            } else {
              this.dataSnack.showResult = true;
              this.dataSnack.color = "error";
              this.dataSnack.result = res.data.error.message;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.dataSnack.showResult = true;
            this.dataSnack.color = "error";
            this.dataSnack.result = "Error! You cannot set Drill.";
          });
      }
    },
    clear() {
      this.dialog = true;
      this.openClear = true;
    },
    openDrill() {
      this.dialog = true;
      this.openClear = false;
    },
    setClear() {
      if (
        this.dataBuilding.passwordBuilding == "" ||
        this.dataBuilding.passwordBuilding == null
      ) {
        this.dataSnack.showResult = true;
        this.dataSnack.color = "error";
        this.dataSnack.result = "Please fill password building.";
      } else {
        this.dataBuilding.id = Cookies.get("idBuilding");
        this.dataBuilding.buildingName = Cookies.get("nameBuilding");

        this.$store
          .dispatch("setClear", this.dataBuilding)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              this.closeDlg();
              this.dataSnack.color = "success";
              this.dataSnack.showResult = true;
              this.dataSnack.result = "Clear events are success.";
              this.setEvent.value = true;
              setTimeout(() => {
                this.setEvent.value = false;
              }, 3000);
            } else {
              this.dataSnack.showResult = true;
              this.dataSnack.color = "error";
              this.dataSnack.result = res.data.error.message;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.dataSnack.showResult = true;
            this.dataSnack.color = "error";
            this.dataSnack.result = "Error! You cannot clear events.";
          });
      }
    },
    onResize() {
      if (this.getMaps > 0) {
        setTimeout(() => {
          var element = document.getElementById(
            this.getMaps[this.onboarding].id
          );
          console.log(element);
          if (element) {
            var positionInfo = element.getBoundingClientRect();
            this.map.height = positionInfo.height;
            this.map.width = positionInfo.width;
            this.map.top = positionInfo.top;
            this.map.left = positionInfo.left;
          }
          if (this.map.height == 0) {
            this.map.height = this.map.width / 1.5;
          }
        }, 1000);
      }

      //console.log(this.map);

      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    calPosition(data) {
      if (data.point) {
        let top = this.map.height * data.point.y;
        let left = this.map.width * data.point.x;
        return `top:${top}px; left:${left}px`;
      }
    },
    loadPage() {
      this.name = Cookies.get("nameBuilding");
      //console.log(this.$route.params.id);
      this.data.id = this.$route.params.id;
      this.$store.dispatch("getMaps", this.data.id).then((res) => {
        // console.log(res);
        if (res.length > 0) {
          setTimeout(() => {
            var element = document.getElementById(res[this.onboarding].id);
            //console.log(element);
            if (element) {
              var positionInfo = element.getBoundingClientRect();
              //console.log(positionInfo);
              this.map.height = positionInfo.width / 1.5;
              this.map.width = positionInfo.width;
              this.map.top = positionInfo.top;
              this.map.left = positionInfo.left;
            }
            if (this.map.height == 0) {
              this.map.height = this.map.width / 1.5;
            }
            this.isShowPoint = true;
            this.loadingMap = false;
          }, 200);
        }
      });

      this.$store.dispatch("getOneBuilding", this.data.id).then((res) => {
        this.name = res.buildingName;
        Cookies.set("dataBuilding", res);
        Cookies.set("nameBuilding", res.buildingName);
        Cookies.set("idBuilding", res.id);
        Cookies.set("idCompany", res.company._id);
      });
    },
    showBorder(item) {
      if (item.dateTimeEnd === "-") {
        return {
          borderLeft: "8px solid red",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      } else {
        return {
          borderLeft: "8px solid white",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      }
    },
    zoomIn(num) {
      console.log(this.$refs.zoomer);
      this.$refs.zoomer[num].zoomIn();
    },
    zoomOut(num) {
      this.$refs.zoomer[num].zoomOut();
    },
    sumhistorys() {
      this.history = true;
    },
    sumevents() {
      this.history = false;
    },
    colorCard(data) {
      switch (data) {
        case "Fire Alarm":
          return "background-image: linear-gradient(185deg,#ea5455,#f2b8b8);";
        case "Trouble":
          return "background-image: linear-gradient(185deg,#f27f0c,#fad7a1);";
        case "Bell":
          return "background-image: linear-gradient(185deg,#2d388a,#8aa5e3);";
        case "Disable":
          return "background-image: linear-gradient(185deg,#8a0fd1,#d7afed);";
        case "Activity":
          return "background-image: linear-gradient(185deg,#1ac45e,#b1e6c6);";
        default:
        // code block
      }
    },
    iconshow(data) {
      switch (data) {
        case "Fire Alarm":
          return "mdi-fire";
        case "Trouble":
          return "mdi-alert";
        case "Bell":
          return "mdi-bell";
        case "Disable":
          return "mdi-cancel";
        case "Activity":
          return "mdi-lightbulb";
        default:
        // code block
      }
    },
    infiniteEvent($state) {
      this.data.last = this.getEvents.data.length;
      console.log(this.data);
      let dataFilter = {
        id: this.data.id,
        filter: this.searchTypeEvent,
        last: this.getEvents.data.length,
        length: 10,
      };
      this.$store.dispatch("getEventByType", dataFilter).then((res) => {
        console.log(res);
        if (res.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    infiniteHis($state) {
      this.data.last = this.getHistorys.data.length;
      //console.log(this.data);
      if (this.hisSearch == true) {
        let dataFilter = {
          id: this.data.id,
          filter: this.searchTypeHis,
          last: this.getHistorys.data.length,
          length: 10,
        };
        this.$store.dispatch("getReportByType", dataFilter).then((res) => {
          console.log(res);
          if (res.length > 0) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      } else {
        this.$store.dispatch("getReportByID", this.data).then((res) => {
          console.log(res);
          if (res.length > 0) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading span {
  position: absolute;
  animation: animate 5s linear infinite;
  border-radius: 50%;
}
.loading span:nth-child(1) {
  animation-delay: 5s;
}
@keyframes animate {
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8);
  }
  0% {
    width: 0;
    height: 0;
    opacity: 1;
    box-shadow: inset 0 0 50px rgba(17, 17, 17, 0.1);
  }
}
</style>
