<template>
  <div
    v-resize="onResize"
    v-if="getEvent && getOneBuilding && getAccess && getBuildings"
  >
    <div v-if="!isMobile">
      <v-app-bar
        app
        color="black"
        dark
        absolute
        style="position: fixed; z-index: 2"
      >
        <div>Fire Alarm Monitoring</div>
        <v-spacer></v-spacer>
        <v-tabs
          :optional="true"
          slider-color="red"
          :exact="true"
          :aria-selected="true"
        >
          <v-tab
            style="width: 150px"
            :class="checkTab(item)"
            :aria-selected="true"
            :exact="true"
            v-for="item in items"
            :key="item.id"
            :to="{ name: item.route, params: { id: idBuilding } }"
            @change="getCookies(item)"
          >
            <font-awesome-icon
              v-if="
                item.title == 'Line Building' || item.title == 'Line Company'
              "
              :color="item.title == path ? '#fff' : '#F0D59'"
              icon="fa-brands fa-line fa-lg"
              style="font-size: 23px"
              class="mr-3"
            />
            <v-icon left v-else> {{ item.icon }} </v-icon>
            <span>
              {{ item.title }}
            </span>
            <div
              v-if="showTab(item)"
              class="v-tabs-slider-wrapper"
              style="height: 2px; left: 0px; width: 150px"
            >
              <div class="v-tabs-slider red"></div>
            </div>
          </v-tab>
        </v-tabs>
        {{ getAccess.name }}
        <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <notification-bell
                :size="20"
                :count="getEvent.number"
                :upperLimit="200"
                :ding="false"
                :animated="true"
                counterLocation="upperRight"
                counterStyle="roundRectangle"
                counterBackgroundColor="#d63636"
                counterTextColor="#FFFFFF"
                iconColor="#fff"
              />
            </v-btn>
          </template>
          <v-card width="400" style="max-height: 500px; overflow-y: auto">
            <v-col>
              <v-card class="text-center" v-if="getEvent.event.length < 1">
                <v-list style="overflow-y: auto">
                  <v-list-item>
                    <v-list-item-content>
                      <span> No Notifications </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </v-card>

              <v-card
                v-else
                v-for="(item, index) in getEvent.event"
                class="my-5 mx-1"
                :style="colorCard(item.type)"
                @click="gotoEvent(item.building)"
              >
                <v-row justify="center" align="center">
                  <v-col cols="2" justify="center" align="center">
                    <v-icon dark class="ml-3">
                      {{ iconshow(item.type) }}
                    </v-icon>
                  </v-col>
                  <v-col style="background-color: #fff">
                    <h4>
                      Message From {{ item.buildingName }}:
                      {{ item.description }}
                    </h4>
                    <span>{{ item.type }}</span>
                    <h5>{{ item.eventStart }}</h5>
                  </v-col>
                </v-row>
              </v-card>
              <infinite-loading
                @infinite="infiniteAllEvent"
                v-if="loadAllEvent"
              ></infinite-loading
            ></v-col>
          </v-card>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                  <v-list-item-subtitle>
                    @{{ getAccess.username }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="openDialog()">
                <v-list-item-action>
                  <v-icon>mdi-key-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title>Change password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title> Logout </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
    </div>
    <div v-else>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#000"
        dense
        dark
      >
        <v-toolbar-title>
          <span> Fire Alarm Monitoring </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <notification-bell
                :size="20"
                :count="getEvent.number"
                :upperLimit="200"
                :ding="false"
                :animated="true"
                counterLocation="upperRight"
                counterStyle="roundRectangle"
                counterBackgroundColor="#d63636"
                counterTextColor="#FFFFFF"
                iconColor="#fff"
              />
            </v-btn>
          </template>
          <v-card width="400" style="max-height: 500px; overflow-y: auto">
            <v-col>
              <v-card
                width="200"
                class="text-center"
                v-if="getEvent.event.length < 1"
              >
                <v-list style="overflow-y: auto">
                  <v-list-item>
                    <v-list-item-content>
                      <span> No Notifications </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </v-card>
              <v-card
                v-else
                v-for="(item, index) in getEvent.event"
                class="my-5 mx-1"
                :style="colorCard(item.type)"
                @click="gotoEvent(item.building)"
              >
                <v-row justify="center" align="center">
                  <v-col cols="2" justify="center" align="center">
                    <v-icon dark class="ml-3">
                      {{ iconshow(item.type) }}
                    </v-icon>
                  </v-col>
                  <v-col style="background-color: #fff">
                    <h4>{{ item.description }}</h4>
                    <span>{{ item.type }}</span>
                    <h5>{{ item.eventStart }}</h5>
                  </v-col>
                </v-row> </v-card
              ><infinite-loading
                @infinite="infiniteAllEvent"
                v-if="loadAllEvent"
              ></infinite-loading>
            </v-col>
          </v-card>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                  <v-list-item-subtitle>
                    @{{ getAccess.username }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="openDialog()">
                <v-list-item-action>
                  <v-icon>mdi-key-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title>Change password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title> Logout </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
    </div>
    <simplert />
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline red darken-2 white--text">
          Change password
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="changePassword"
            ref="changPassword"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              :type="password.hideOld ? 'text' : 'password'"
              :append-icon="
                password.hideOld ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="old password"
              label="Old Password"
              hide-details="auto"
              v-model="password.old"
              @click:append="password.hideOld = !password.hideOld"
              :rules="[(v) => !!v || 'Old Password is required']"
              required
            >
            </v-text-field>
            <v-text-field
              :type="password.hideNew ? 'text' : 'password'"
              :append-icon="
                password.hideNew ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="new password"
              label="New Password"
              hide-details="auto"
              v-model="password.new"
              @click:append="password.hideNew = !password.hideNew"
              :rules="[(v) => !!v || 'New Password is required']"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="changePassword()">Change</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="popup" width="400" persistent>
      <v-card>
        <v-card-title class="justify-center align-center">
          <v-avatar color="black" size="65" class="mb-2">
            <v-icon dark x-large> mdi-volume-high </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle class="text-h6 text-center mt-1">
          Allow sites to play sound
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark large @click="allowSound()" class="mb-2">
            I Allow
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
    <notifications
      group="custom-template"
      position="bottom right"
      :max="2"
      :style="sizeNoti()"
    >
      <template slot="body" slot-scope="props">
        <div class="ma-5">
          <v-card :style="colorCard(props.item.data.type)">
            <v-row justify="center" align="center">
              <v-col
                cols="3"
                lg="2"
                md="2"
                sm="3"
                justify="center"
                align="center"
              >
                <v-icon dark class="ml-2" style="font-size: 30px">
                  {{ iconshow(props.item.data.type) }}
                </v-icon>
              </v-col>
              <v-col style="background-color: #fff">
                <v-row justify="center" class="pa-0">
                  <v-col class="pb-0 pt-1">
                    <h3>{{ props.item.data.type }}</h3>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-btn icon @click="props.close">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{
                    name: 'Events',
                    params: { id: props.item.data.building },
                  }"
                >
                  <v-row justify="center">
                    <v-col>
                      <span>{{ props.item.data.description }}</span>
                      <span>{{ props.item.data.eventStart }}</span>
                    </v-col>
                  </v-row>
                </router-link>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>
import NotificationBell from "vue-notification-bell";
import Cookies from "js-cookie";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    NotificationBell,
    InfiniteLoading, // Registering Component
  },

  computed: {
    isBuildingManu() {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (
        this.path == "Events" ||
        this.path == "Map" ||
        this.path == "Keywords" ||
        this.path == "LineBuildings"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDashboardManu() {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (this.path == "Dashboard") {
        return true;
      } else {
        return false;
      }
    },
    getBuildings() {
      //console.log(this.$store.getters.getBuildings);
      if (this.path == "Dashboard") {
        if (this.$store.getters.getBuildings.sound == true) {
          if (this.eventSound.paused) {
            this.eventSound.loop = true;
            var resp = this.eventSound.play();

            if (resp !== undefined) {
              resp
                .then((_) => {
                  this.eventSound.play();
                })
                .catch((error) => {
                  this.popup = true;
                });
            }
          }
        } else {
          this.eventSound.pause();
        }
      }
      return this.$store.getters.getBuildings;
    },
    getOneBuilding() {
      //console.log(this.path);
      //console.log(this.$store.getters.oneBuilding);
      if (
        this.path == "Events" ||
        this.path == "Map" ||
        this.path == "Keywords" ||
        this.path == "LineBuildings"
      ) {
        if (this.$store.getters.oneBuilding.sound == true) {
          if (this.eventSound.paused) {
            this.eventSound.loop = true;
            var resp = this.eventSound.play();
            if (resp !== undefined) {
              resp
                .then((_) => {
                  this.eventSound.play();
                })
                .catch((error) => {
                  this.popup = true;
                });
            }
          }
        } else {
          this.eventSound.pause();
        }
      }
      return this.$store.getters.oneBuilding;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getEvent() {
      return this.$store.getters.allEvent;
    },
    loadAllEvent() {
      return this.$store.getters.infiniteAllEvent;
    },
  },
  mounted() {
    //console.log(this.getAccess);
    this.$store.dispatch("auth/decode").then(() => {
      //console.log(this.getAccess);
      if (this.getAccess) {
        this.sockets.subscribe("status", (data) => {
          this.idBuilding = Cookies.get("idBuilding");
          if (data.type == "notification") {
            this.noti(data.data);
          }
        });
      }
    });
  },
  beforeDestroy() {
    //console.log("beforeDestroy");
    this.eventSound.pause();
  },
  watch: {
    $route(to, from) {
      console.log(to.name);
      this.path = to.name;
      if (from.name == "Events") {
        //console.log("dd");
        this.$store.dispatch("clearEvent");
        this.$store.dispatch("clearHis");
        this.$store.dispatch("clearInfinite");
      }
      if (from.name == "Reports") {
        this.$store.dispatch("clearReport");
      }
      if (to) {
        this.$Simplert.close();
      }
      if (
        to.name == "Events" ||
        to.name == "Map" ||
        to.name == "Keywords" ||
        to.name == "LineBuildings" ||
        to.name == "Configs" ||
        to.name == "RealTime"
      ) {
        this.idBuilding = Cookies.get("idBuilding");
        console.log(this.idBuilding);
        if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          this.items = this.buildingManuAdmin;
        } else if (this.getAccess.role == "Superadmin") {
          this.items = this.buildingManu;

          if (this.getAccess.username == "pmAdmin") {
            const index = this.items.findIndex((u) => u.title == "Configs");
            if (index == -1) {
              this.items.push({
                title: "Configs",
                icon: "mdi-square-edit-outline",
                route: "Configs",
              });
            }
          }
        } else {
          this.items = this.buildingManuUser;
        }
      } else if (
        to.name == "Dashboard" ||
        to.name == "Reports" ||
        to.name == "LineCompanies" ||
        to.name == "Accounts" ||
        to.name == "Companies" ||
        to.name == "Buildings"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.SuperAdminManu;
        } else if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          this.items = this.AdminManu;
        } else {
          this.items = this.UserManu;
        }
        if (to.name != "Dashboard") {
          this.eventSound.pause();
        }
      } else {
        this.eventSound.pause();
      }
    },
  },

  created() {
    this.$store.dispatch("auth/decode").then(() => {
      //console.log(this.getAccess);
      if (this.getAccess) {
        this.checkManu();
        let data = {
          last: 0,
          length: 4,
        };
        this.$store.dispatch("getAllEvents", data).then(() => {
          setTimeout(() => {
            this.popup = true;
          }, 1000);
        });
        this.path = this.$router.currentRoute.name;
      }
    });
  },
  data() {
    return {
      selectedTab: null,
      popup: false,
      index: "",
      active: "",
      idBuilding: null,
      dataBuilding: null,
      mute: false,
      eventSound: new Audio(require("../assets/event.mp3")),
      isMobile: false,
      dialog: false,
      drawer: null,
      path: null,
      items: null,
      homePath: true,
      password: {
        old: "",
        new: "",
        hideOld: false,
        hideNew: false,
      },
      payload: {
        id: "",
        newPassword: "",
        password: "",
      },
      pageBuildings: false,
      snackbar: false,
      text: "",
      color: "",
      UserManu: [
        {
          id: 0,
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "Dashboard",
        },
        {
          id: 1,
          title: "Reports",
          icon: "mdi-file-document",
          route: "Reports",
        },
      ],
      buildingManuAdmin: [
        {
          id: 0,
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "Dashboard",
        },
        {
          id: 1,
          title: "Events",
          icon: "mdi-bell-alert",
          route: "Events",
        },
        {
          id: 2,
          title: "Maps",
          icon: "mdi-map",
          route: "Map",
        },

        {
          id: 4,
          title: "Line Building",
          icon: "fa-line",
          route: "LineBuildings",
        },
      ],
      buildingManuUser: [
        {
          id: 0,
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "Dashboard",
        },
        {
          id: 1,
          title: "Events",
          icon: "mdi-bell-alert",
          route: "Events",
        },
      ],
      buildingManu: [
        {
          id: 0,
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "Dashboard",
        },
        {
          id: 1,
          title: "Events",
          icon: "mdi-bell-alert",
          route: "Events",
        },
        {
          id: 2,
          title: "Maps",
          icon: "mdi-map",
          route: "Map",
        },
        {
          id: 3,
          title: "Keyword",
          icon: "mdi-file-key",
          route: "Keywords",
        },
        {
          id: 4,
          title: "Line Building",
          icon: "fa-line",
          route: "LineBuildings",
        },
        {
          id: 5,
          title: "RealTime",
          icon: "mdi-clipboard-text-clock",
          route: "RealTime",
        },
      ],
      AdminManu: [
        { title: "Dashboard", icon: "mdi-view-dashboard", route: "Dashboard" },
        { title: "Reports", icon: "mdi-file-document", route: "Reports" },
        { title: "Line Company", icon: "fa-line", route: "LineCompanies" },
      ],

      SuperAdminManu: [
        { title: "Dashboard", icon: "mdi-view-dashboard", route: "Dashboard" },
        { title: "Reports", icon: "mdi-file-document", route: "Reports" },
        { title: "Accounts", icon: "mdi-account", route: "Accounts" },
        { title: "Companies", icon: "mdi-sitemap ", route: "Companies" },
        {
          title: "Buildings",
          icon: "mdi-office-building",
          route: "Buildings",
        },
      ],
    };
  },
  methods: {
    infiniteAllEvent($state) {
      let data = {
        last: this.getEvent.event.length,
        length: 5,
      };
      this.$store.dispatch("getAllEvents", data).then((res) => {
        console.log(res.event);
        if (res.event.length > 0) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    sizeNoti() {
      if (this.isMobile) {
        return "width: 300px";
      } else {
        return "width: 30vw";
      }
    },
    checkTab(item) {
      this.path = this.$router.currentRoute.name;
      if (item.route == this.path) {
        return "v-tab--active";
      }
    },
    showTab(item) {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      //console.log(item);
      if (item.route == this.path) {
        return true;
      } else {
        return false;
      }
    },
    event() {
      this.idBuilding = Cookies.get("idBuilding");
      this.$router.push(`/building/events/${this.idBuilding}`);
    },
    gotoEvent(id) {
      console.log(id);
      //this.$router.push({ name: "Events", params: { id: id } });
      if (this.$route.path != `/building/events/${id}`) {
        this.$router.push({ name: "Events", params: { id: id } });
      }
    },
    getCookies(item) {
      this.idBuilding = Cookies.get("idBuilding");
    },
    checkSound() {
      console.log(this.getOneBuilding);
      if (this.getOneBuilding.sound == true) {
        if (this.eventSound.paused) {
          this.eventSound.loop = true;
          this.eventSound.play();
        }
      } else {
        this.eventSound.pause();
      }

      //this.idBuilding = Cookies.get("idBuilding");
    },
    allowSound() {
      this.eventSound.mute = false;
      this.popup = false;
      this.path = this.$router.currentRoute.name;
      if (this.path == "Dashboard") {
        console.log(this.getBuildings);
        if (this.getBuildings.sound == true) {
          console.log("play");
          if (this.eventSound.paused) {
            this.eventSound.loop = true;
            this.eventSound.play();
          }
        } else {
          this.eventSound.pause();
        }
      }
      if (
        this.path == "Events" ||
        this.path == "Map" ||
        this.path == "Keywords" ||
        this.path == "LineBuildings"
      ) {
        this.checkSound();
      }
    },
    colorCard(data) {
      switch (data) {
        case "Fire Alarm":
          return "background-image: linear-gradient(185deg,#ea5455,#fad7a1);";
        case "Trouble":
          return "background-image: linear-gradient(185deg,#f27f0c,#fad7a1);";
        case "Bell":
          return "background-image: linear-gradient(185deg,#97d9e1,#8e2fa8);";
        case "Disable":
          return "background-image: linear-gradient(185deg,#28c768,#ffdee9);";
        case "Activity":
          return "background-image: linear-gradient(185deg,#7367f0,#ffdee9);";
        default:
        // code block
      }
    },
    iconshow(data) {
      switch (data) {
        case "Fire Alarm":
          return "mdi-fire-alert";
        case "Trouble":
          return "mdi-alert";
        case "Bell":
          return "mdi-bell-alert";
        case "Disable":
          return "mdi-cancel";
        case "Activity":
          return "mdi-lightbulb-alert";
        default:
        // code block
      }
    },
    noti(data) {
      console.log(this.loggedIn);
      console.log(this.eventSound.paused);
      this.$store.dispatch("auth/decode").then(() => {
        console.log(this.getAccess);

        if (
          this.getAccess.role == "Superadmin" ||
          (this.getAccess.role != "Superadmin" &&
            this.getAccess.company == data.company)
        ) {
          this.$notify({
            group: "custom-template",
            title: `${data.eventStart}`,
            text: `${data.description}`,
            closeOnClick: true,
            data: data,
            duration: 10000,
            max: 2,
          });
        }
      });
    },
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.password.new = "";
      this.password.old = "";
    },
    changePassword() {
      this.payload.id = this.getAccess.id;
      this.payload.newPassword = this.password.new;
      this.payload.password = this.password.old;
      if (this.payload.newPassword == "" || this.payload.old == "") {
        this.text = "Error! Please fill up this form.";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store.dispatch("auth/changePassword", this.payload).then(
          () => {
            this.dialog = false;
            this.color = "success";
            this.text = "Success! Your Password has been changed!";
            this.snackbar = true;
          },
          () => {
            this.text = "Error! Your old password is incorrect!";
            this.color = "red";
            this.snackbar = true;
          }
        );
      }
    },
    checkManu() {
      //console.log(this.eventSound);
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);

      if (
        this.path == "Events" ||
        this.path == "Map" ||
        this.path == "Keywords" ||
        this.path == "LineBuildings" ||
        this.path == "Configs" ||
        this.path == "RealTime"
      ) {
        if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          this.items = this.buildingManuAdmin;
        } else if (this.getAccess.role == "Superadmin") {
          this.items = this.buildingManu;
          if (this.getAccess.username == "pmAdmin") {
            this.items.push({
              title: "Configs",
              icon: "mdi-square-edit-outline",
              route: "Configs",
            });
          }
        } else {
          this.items = this.buildingManuUser;
        }
      } else if (
        this.path == "Dashboard" ||
        this.path == "Reports" ||
        this.path == "LineCompanies" ||
        this.path == "Accounts" ||
        this.path == "Companies" ||
        this.path == "Buildings"
      ) {
        if (this.getAccess.role == "Superadmin") {
          this.items = this.SuperAdminManu;
        } else if (
          this.getAccess.role == "Admin" ||
          this.getAccess.role == "AdminDrill"
        ) {
          this.items = this.AdminManu;
        } else {
          this.items = this.UserManu;
        }
      }
    },
    logout() {
      let confirmFn = function () {
        try {
          this.$store.dispatch("auth/logout").then(() => {
            this.$router.push("/");
          });
        } catch (error) {
          //console.log(error);
        }
      };
      let obj = {
        message:
          '<span style="font-size: 180%; font-weight: bold;">You want to log out?</span>',
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "  Yes  ",
        customCloseBtnText: "Cancel",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
      };
      this.$Simplert.open(obj);
    },
  },
};
</script>
<style>
.same {
  width: 85px;
}
</style>
