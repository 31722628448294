<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-card id="card" class="ma-1">
        <v-card-title>
          Line Building
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          item-key="id"
          :search="search"
          :headers="headers"
          :items="getLines"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  @click="delData(item._id)"
                  color="error"
                  icon
                >
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn @click="delData(item._id)" color="error" icon>
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="form" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Manual Add Line</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addLine()"
                  @keyup.native.enter="addLine()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-select
                      :items="getLinesCom"
                      item-text="name"
                      name="line"
                      label="Line"
                      return-object
                      v-model="lineData"
                      required
                    ></v-select>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="addLine()">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this config line ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delLine()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" top right :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      id: null,
      idCompany: null,
      isMobile: false,
      isApp: false,
      form: false,
      editForm: false,
      search: null,
      lineData: null,
      idDelete: null,
      color: null,
      text: null,
      snackbar: false,
      confirmDelete: false,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Line",
          disabled: true,
          href: "/lineConfig",
        },
      ],
    };
  },
  computed: {
    getLines() {
      return this.$store.getters.allLinesBuilding;
    },
    getLinesCom() {
      return this.$store.getters.allLinesCom;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.$route.params.data != null) {
      this.id = this.$route.params.data.id;
      this.idCompany = this.$route.params.data.company._id;
      Cookies.set("idBuilding", this.id);
      Cookies.set("idCompany", this.idCompany);
    }
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role == "User") {
      this.$router.push("/");
    } else {
      this.id = Cookies.get("idBuilding");
      this.idCompany = Cookies.get("idCompany");
      this.loadLine();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadLine() {
      this.$store.dispatch("getLinesBuilding", this.id);
      this.$store.dispatch("getLinesCom", this.idCompany);
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editForm = false;
      this.lineData = null;
    },
    delData(id) {
      this.confirmDelete = true;
      this.idDelete = id;
    },
    addLine() {
      console.log(this.lineData);
      this.lineData.building = Cookies.get("idBuilding");
      this.$store
        .dispatch("createLineBuilding", this.lineData)
        .then((res) => {
          if (res.status == 200) {
            this.closeDlg();
            
            this.color = "success";
            this.text = "Config Line  has been successfully saved.";
            this.snackbar = true;
          } else if (res.status == 400) {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = "Error! You cannot create config line .";
        });
    },
    delLine() {
      this.$store
        .dispatch("deleteLineBuilding", this.idDelete)
        .then((res) => {
          if (res.status == 200) {
            this.closeDlg();
            
            this.color = "success";
            this.text = "Deleted! Config Line has been deleted.";
            this.snackbar = true;
          } else if (res.status == 400) {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config Line.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style></style>