<template>
  <v-container fluid v-resize="onResize">
    <h1>
      {{ this.$router.currentRoute.name }}
    </h1>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="8"
        lg="8"
        align="center"
        justify="center"
      >
        <v-text-field
          v-model="search"
          hide-details
          class="mb-0"
          label="Search"
          dense
          outlined
          color="red"
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col>
        <v-btn-toggle v-model="toggle" dense color="red">
          <v-btn class="text-upper"> dashboard </v-btn>
          <v-btn class="text-upper"> table </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row justify="start" v-if="toggle == 0 && loading == false">
      <v-col
        class="d-flex justify-center text-center"
        v-for="(item, index) in getBuilding.building"
        :key="index"
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <v-card id="card" class="mt-1 mb-0" width="650px">
          <v-row>
            <v-col>
              <h1
                class="mt-1 font-weight-bold text-center"
                style="font-size: 180%"
              >
                {{ item.buildingName }}
              </h1>
              <h4
                class="mt-1 font-weight-thin text-center text--secondary"
                v-if="getAccess.role == 'Superadmin'"
              >
                {{ item.company.name }}
              </h4>
            </v-col>
            <v-icon
              v-if="item.sound"
              class="blink"
              color="red"
              style="
                top: 10px;
                left: 85%;
                position: absolute;
                border-radius: 4px;
                font-size: 160%;
              "
            >
              mdi-alert-circle</v-icon
            >
            <v-icon
              v-if="item.bell > 0"
              class="blink"
              color="red"
              style="
                top: 10px;
                left: 93%;
                position: absolute;
                border-radius: 4px;
                font-size: 160%;
              "
            >
              mdi-bell-ring</v-icon
            >
          </v-row>
          <v-row>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="6" class="text-center" v-bind="attrs" v-on="on">
                  <h4 v-if="item.status === 'Online'" class="green--text">
                    <v-icon color="green"> mdi-alarm-light</v-icon>
                    Online
                  </h4>
                  <h4 v-else class="red--text">
                    <v-icon color="red"> mdi-alarm-light-off</v-icon>
                    Offline
                  </h4>
                </v-col>
              </template>
              <span>Gateway</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="6" class="text-center" v-bind="attrs" v-on="on">
                  <h4 v-if="item.io === 'Online'" class="green--text">
                    <v-icon color="green"> mdi-access-point-network</v-icon>
                    Online
                  </h4>
                  <h4 v-else class="red--text">
                    <v-icon color="red"> mdi-access-point-network-off </v-icon>
                    Offline
                  </h4>
                </v-col>
              </template>
              <span>SW-I/O</span>
            </v-tooltip>
          </v-row>
          <v-row class="text-center my-1 pa-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on">
                  <h4
                    v-if="item.fireAlarm > 0"
                    style="
                      background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    "
                  >
                    <v-icon
                      style="
                        background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      "
                      class="mt-n2"
                      >mdi-fire-alert</v-icon
                    >
                    {{ item.fireAlarm }}
                  </h4>
                  <h4 v-else class="grey--text">
                    <v-icon class="mt-n2">mdi-fire-alert</v-icon>
                    {{ item.fireAlarm }}
                  </h4>
                </v-col>
              </template>
              <span>Fire Alarm</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on">
                  <h4
                    v-if="item.trouble > 0"
                    style="
                      background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    "
                  >
                    <v-icon
                      style="
                        background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      "
                      class="mt-n1"
                      >mdi-alert</v-icon
                    >
                    {{ item.trouble }}
                  </h4>
                  <h4 v-else class="grey--text">
                    <v-icon class="mt-n1">mdi-alert</v-icon>
                    {{ item.trouble }}
                  </h4>
                </v-col>
              </template>
              <span>Trouble</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on">
                  <h4
                    v-if="item.bell > 0"
                    style="
                      background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    "
                  >
                    <v-icon
                      style="
                        background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      "
                      class="mt-n1"
                      >mdi-bell-alert</v-icon
                    >
                    {{ item.bell }}
                  </h4>
                  <h4 v-else class="grey--text">
                    <v-icon class="mt-n1">mdi-bell-alert</v-icon>
                    {{ item.bell }}
                  </h4>
                </v-col>
              </template>
              <span>Bell</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on">
                  <h4
                    v-if="item.disable > 0"
                    style="
                      background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    "
                  >
                    <v-icon
                      style="
                        background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      "
                      class="mt-n1"
                      >mdi-cancel</v-icon
                    >
                    {{ item.disable }}
                  </h4>
                  <h4 v-else class="grey--text">
                    <v-icon class="mt-n1">mdi-cancel</v-icon>
                    {{ item.disable }}
                  </h4>
                </v-col>
              </template>
              <span>Disable</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on">
                  <h4
                    v-if="item.activity > 0"
                    style="
                      background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    "
                  >
                    <v-icon
                      style="
                        background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      "
                      class="mt-n1"
                      >mdi-lightbulb-alert</v-icon
                    >
                    {{ item.activity }}
                  </h4>
                  <h4 v-else class="grey--text">
                    <v-icon class="mt-n1">mdi-lightbulb-alert</v-icon>
                    {{ item.activity }}
                  </h4>
                </v-col>
              </template>
              <span>Activity</span>
            </v-tooltip>
          </v-row>
          <v-col cols="12" class="my-1 mx-0 px-0 pt-0">
            <router-link
              style="color: inherit; text-decoration: none"
              :to="{ name: 'Events', params: { id: item.id, data: item } }"
            >
              <v-btn
                :small="isMobile"
                :width="isMobile ? '80px' : '120px'"
                dark
                color="#e69191"
                style="
                  border-style: solid;
                  border-right: 0px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                "
              >
                <v-icon dark left v-if="!isMobile">mdi-bell</v-icon>
                Events
              </v-btn></router-link
            >

            <router-link
              style="color: inherit; text-decoration: none"
              :to="{ name: 'Map', params: { data: item } }"
            >
              <v-btn
                :small="isMobile"
                v-if="getAccess.role != 'User'"
                dark
                color="#f2c994"
                :width="isMobile ? '80px' : '120px'"
                style="
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                "
              >
                <v-icon dark left v-if="!isMobile"
                  >mdi-map-marker-radius</v-icon
                >
                Maps
              </v-btn>
            </router-link>

            <router-link
              :to="{ name: 'Keywords', params: { data: item } }"
              style="color: inherit; text-decoration: none"
            >
              <v-btn
                :small="isMobile"
                v-if="getAccess.role == 'Superadmin'"
                dark
                color="#ede66f"
                :width="isMobile ? '80px' : '120px'"
                style="
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                "
              >
                <v-icon dark left v-if="!isMobile"> mdi-file-key </v-icon>
                Keywords
              </v-btn>
            </router-link>

            <router-link
              style="color: inherit; text-decoration: none"
              :to="{ name: 'LineBuildings', params: { data: item } }"
            >
              <v-btn
                v-if="getAccess.role != 'User'"
                :small="isMobile"
                dark
                color="#93cf82"
                :width="isMobile ? '80px' : '120px'"
                style="
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                "
              >
                <font-awesome-icon
                  v-if="!isMobile"
                  icon="fa-brands fa-line fa-sm"
                  style="font-size: 20px"
                  class="pl-0 mr-2"
                />

                Lines
              </v-btn>
            </router-link>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="start" v-if="toggle == 1">
      <v-container fluid>
        <v-card>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="searchBuilding"
            :items-per-page="5"
            mobile-breakpoint="0"
            class="elevation-1"
            v-if="!isMobile"
          >
            <template v-slot:[`item.buildingName`]="{ item }">
              <v-row>
                <v-icon v-if="item.sound" class="blink" color="red">
                  mdi-alert-circle</v-icon
                >
                <v-icon v-if="item.bell > 0" class="blink" color="red">
                  mdi-bell-ring</v-icon
                >
                <span class="ml-2">
                  {{ item.buildingName }}
                </span>
              </v-row>
            </template>
            <template v-slot:[`item.statusio`]="{ item }">
              <v-row class="text-center" justify="center">
                <h4 v-if="item.io === 'Online'" class="mb-2 mt-1 green--text">
                  <v-icon color="green"> mdi-access-point-network</v-icon>
                  Online
                </h4>
                <h4 v-else class="mb-2 mt-1 red--text">
                  <v-icon color="red"> mdi-access-point-network-off</v-icon>
                  Offline
                </h4>
              </v-row>
            </template>
            <template v-slot:[`item.statusbox`]="{ item }">
              <v-row class="text-center" justify="center">
                <h4
                  v-if="item.status === 'Online'"
                  class="mb-2 mt-1 green--text"
                >
                  <v-icon color="green"> mdi-alarm-light</v-icon>
                  Online
                </h4>
                <h4 v-else class="mb-2 mt-1 red--text">
                  <v-icon color="red"> mdi-alarm-light-off</v-icon>
                  Offline
                </h4>
              </v-row>
            </template>
            <template v-slot:[`item.events`]="{ item }">
              <v-row class="text-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.fireAlarm > 0"
                        style="
                          background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n2"
                          >mdi-fire-alert</v-icon
                        >
                        {{ item.fireAlarm }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-fire-alert</v-icon>
                        {{ item.fireAlarm }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Fire Alarm</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.trouble > 0"
                        style="
                          background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          
                          style="background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-alert</v-icon
                        >
                        {{ item.trouble }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-alert</v-icon>
                        {{ item.trouble }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Trouble</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.bell > 0"
                        style="
                          background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          
                          style="background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-bell-alert</v-icon
                        >
                        {{ item.bell }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-bell-alert</v-icon>
                        {{ item.bell }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Bell</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.disable > 0"
                        style="
                          background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-cancel</v-icon
                        >
                        {{ item.disable }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-cancel</v-icon>
                        {{ item.disable }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Disable</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.activity > 0"
                        style="
                          background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-lightbulb-alert</v-icon
                        >
                        {{ item.activity }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-lightbulb-alert</v-icon>
                        {{ item.activity }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Activity</span>
                </v-tooltip>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-col cols="12" class="mt-1">
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'Events', params: { id: item.id, data: item } }"
                >
                  <v-btn
                    dark
                    color="#e69191"
                    style="
                      border-style: solid;
                      border-right: 0px;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    "
                    :small="mobileTable"
                    :width="mobileTable ? '69px' : '110px'"
                  >
                    <v-icon dark left v-if="!mobileTable">mdi-bell</v-icon>
                    Events
                  </v-btn></router-link
                >

                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'Map', params: { data: item } }"
                >
                  <v-btn
                    v-if="getAccess.role != 'User'"
                    dark
                    color="#f2c994"
                    :small="mobileTable"
                    :width="mobileTable ? '69px' : '110px'"
                    style="
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                  >
                    <v-icon dark left v-if="!mobileTable"
                      >mdi-map-marker-radius</v-icon
                    >
                    Maps
                  </v-btn>
                </router-link>

                <router-link
                  :to="{ name: 'Keywords', params: { data: item } }"
                  style="color: inherit; text-decoration: none"
                >
                  <v-btn
                    v-if="getAccess.role == 'Superadmin'"
                    dark
                    color="#ede66f"
                    :small="mobileTable"
                    :width="mobileTable ? '69px' : '110px'"
                    style="
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    "
                  >
                    <v-icon dark left v-if="!mobileTable">
                      mdi-file-key
                    </v-icon>
                    Keywords
                  </v-btn>
                </router-link>

                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'LineBuildings', params: { data: item } }"
                >
                  <v-btn
                    v-if="getAccess.role != 'User'"
                    dark
                    color="#93cf82"
                    :small="mobileTable"
                    :width="mobileTable ? '69px' : '110px'"
                    style="
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                  >
                    <font-awesome-icon
                      v-if="!mobileTable"
                      icon="fa-brands fa-line fa-sm"
                      style="font-size: 20px"
                      class="pl-0 mr-2"
                    />

                    Lines
                  </v-btn>
                </router-link>
              </v-col>
            </template>
          </v-data-table>
          <v-data-table
            :search="search"
            :headers="headersMobile"
            :items="searchBuilding"
            :items-per-page="5"
            :options.sync="options"
            mobile-breakpoint="0"
            class="elevation-1"
            v-else
          >
            <template v-slot:[`item.buildingName`]="{ item }">
              <v-row>
                <v-icon v-if="item.sound" class="blink" color="red">
                  mdi-alert-circle</v-icon
                >
                <v-icon v-if="item.bell > 0" class="blink" color="red">
                  mdi-bell-ring</v-icon
                >
                <span class="ml-2">
                  {{ item.buildingName }}
                </span>
              </v-row>
            </template>
            <template v-slot:[`item.statusio`]="{ item }">
              <v-row class="text-center" justify="center">
                <h4 v-if="item.io === 'Online'" class="mb-2 mt-1 green--text">
                  <v-icon color="green"> mdi-access-point-network</v-icon>
                </h4>
                <h4 v-else class="mb-2 mt-1 red--text">
                  <v-icon color="red"> mdi-access-point-network-off</v-icon>
                </h4>
              </v-row>
            </template>
            <template v-slot:[`item.statusbox`]="{ item }">
              <v-row class="text-center" justify="center">
                <h4
                  v-if="item.status === 'Online'"
                  class="mb-2 mt-1 green--text"
                >
                  <v-icon color="green"> mdi-alarm-light</v-icon>
                </h4>
                <h4 v-else class="mb-2 mt-1 red--text">
                  <v-icon color="red"> mdi-alarm-light-off</v-icon>
                </h4>
              </v-row>
            </template>
            <template v-slot:[`item.events`]="{ item }" style="width: 10%">
              <v-row class="text-center">
                <v-tooltip top v-if="numEvent == 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.fireAlarm > 0"
                        style="
                          background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#ea5455,#f2b8b8);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n2"
                          >mdi-fire-alert</v-icon
                        >
                        {{ item.fireAlarm }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-fire-alert</v-icon>
                        {{ item.fireAlarm }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Fire Alarm</span>
                </v-tooltip>
                <v-tooltip top v-if="numEvent == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.trouble > 0"
                        style="
                          background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#f27f0c,#fad7a1);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-alert</v-icon
                        >
                        {{ item.trouble }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-alert</v-icon>
                        {{ item.trouble }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Trouble</span>
                </v-tooltip>
                <v-tooltip top v-if="numEvent == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.bell > 0"
                        style="
                          background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#2d388a,#8aa5e3);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-bell-alert</v-icon
                        >
                        {{ item.bell }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-bell-alert</v-icon>
                        {{ item.bell }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Bell</span>
                </v-tooltip>

                <v-tooltip top v-if="numEvent == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.disable > 0"
                        style="
                          background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#1ac45e,#b1e6c6);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-cancel</v-icon
                        >
                        {{ item.disable }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-cancel</v-icon>
                        {{ item.disable }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Disable</span>
                </v-tooltip>
                <v-tooltip top v-if="numEvent == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on">
                      <h4
                        v-if="item.activity > 0"
                        style="
                          background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        "
                      >
                        <v-icon
                          style="
                            background: -webkit-linear-gradient(#8a0fd1,#d7afed);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                          "
                          class="mt-n1"
                          >mdi-lightbulb-alert</v-icon
                        >
                        {{ item.activity }}
                      </h4>
                      <h4 v-else class="grey--text">
                        <v-icon class="mt-n1">mdi-lightbulb-alert</v-icon>
                        {{ item.activity }}
                      </h4>
                    </v-col>
                  </template>
                  <span>Activity</span>
                </v-tooltip>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }" style="width: 20%">
              <v-col cols="12" class="mt-1">
                <router-link
                  style="color: inherit; text-decoration: none"
                  :to="{ name: 'Events', params: { id: item.id, data: item } }"
                >
                  <v-btn
                    dark
                    color="#e69191"
                    style="
                      border-style: solid;
                      border-right: 0px;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                    "
                    :small="mobileTable"
                    :width="mobileTable ? '69px' : '110px'"
                  >
                    <v-icon dark left v-if="!mobileTable">mdi-bell</v-icon>
                    Events
                  </v-btn></router-link
                >
              </v-col>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-row>
    <infinite-loading
      @infinite="infiniteHandler"
      v-if="this.load"
    ></infinite-loading>
  </v-container>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getBuilding() {
      console.log(this.$store.getters.getBuildings);
      return this.$store.getters.getBuildings;
    },
    searchBuilding: function () {
      return this.getBuilding.building.filter((building) => {
        return building.buildingName.match(this.search);
      });
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.toggle == 1) {
          this.checkNumTable();
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    //console.log("ddd");
    clearInterval(this.interval);
    next();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.interval = setInterval(() => {
        this.numEvent++; //Increment your variable
        if (this.numEvent > 4) {
          this.numEvent = 0;
        } //Stop the interval when you reach 100
      }, 3000);
    }
    if (this.getAccess.role === "Superadmin") {
      this.headers.splice(3, 0, {
        text: "Company",
        value: "company.name",
        sortable: false,
        width: "8%",
        align: "center",
      });
      this.headers.Company = "company.name";
      this.headersMobile.splice(3, 0, {
        text: "Company",
        value: "company.name",
        sortable: false,
        width: "20px",
        align: "center",
      });
      this.headersMobile.Company = "company.name";
    }
  },
  data() {
    return {
      options: {},
      interval: undefined,
      numEvent: 0,
      isMobile: false,
      mobileTable: false,
      toggle: 0,
      loading: true,
      load: true,
      buildings: [],
      search: "",
      data: {
        last: 0,
        length: 0,
      },

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "buildingName",
          width: "8%",
        },

        {
          text: "Gateway",
          value: "statusbox",
          sortable: false,
          align: "center",
          width: "8%",
        },
        {
          text: "SW-I/O",
          value: "statusio",
          sortable: false,
          align: "center",
          width: "8%",
        },
        {
          text: "Event",
          value: "events",
          sortable: false,
          align: "center",
          width: "30%",
        },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
      headersMobile: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "buildingName",
          width: "40px",
        },

        {
          text: "Gateway",
          value: "statusbox",
          sortable: false,
          align: "center",
          width: "40px",
        },
        {
          text: "SW-I/O",
          value: "statusio",
          sortable: false,
          align: "center",
          width: "50px",
        },
        {
          text: "Event",
          value: "events",
          sortable: false,
          align: "center",
          width: "40px",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
          width: "30px",
        },
      ],
    };
  },
  methods: {
    checkNumTable: async function () {
      const { page, itemsPerPage } = this.options;
      console.log(page);
      let dataShow = page * itemsPerPage;
      if (this.getBuilding.building.length - dataShow < itemsPerPage) {
        let data = {
          last: this.getBuilding.building.length,
          length: itemsPerPage,
        };
        this.$store.dispatch("getBuildings", data).then((res) => {
          if (res.length === 0) {
            this.load = false;
          }
        });
      }
    },
    onResize() {
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (window.innerWidth < 1300) {
        this.mobileTable = true;
      } else {
        this.mobileTable = false;
      }
    },

    infiniteHandler($state) {
      if (this.data.length === 0) {
        this.data.length = this.cheackScreenInitial();
      } else {
        this.data.length = this.cheackScreenNext();
      }
      this.data.last = this.getBuilding.building.length;
      //console.log(this.data);
      //this.load = false;
      this.$store.dispatch("getBuildings", this.data).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.data.building.length == 0) {
          console.log(res.length);
          this.load = false;
        }
        if (res.status == 500) {
          this.load = false;
        }
        setTimeout(() => {
          $state.loaded();
        }, 100);
      });
    },
    cheackScreenInitial() {
      if (window.matchMedia("screen and (max-width:500px)").matches) {
        return 2;
      } else if (window.matchMedia("screen and (max-width:960px)").matches) {
        return 2;
      } else if (window.matchMedia("screen and (max-width:1264px)").matches) {
        return 12;
      } else {
        return 15;
      }
    },
    cheackScreenNext() {
      if (window.matchMedia("screen and (max-width:960px)").matches) {
        return 4;
      } else {
        return 3;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#card {
  border-top: 5px solid red;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
