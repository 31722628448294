import Router from "../router/index";
import Cookies from "js-cookie";
function removeStorage(name) {
  try {
    Cookies.remove(name);
    Router.push("/");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        name +
        "] from cookies: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
export default function getStorage(key) {
  //console.log(key)
  var now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  //var expiresIn = localStorage.getItem(key + "_expiresIn");
  let obj = Cookies.getJSON(key);
  if (obj) {
     if (obj.expires_in === undefined || obj.expires_in === null) {
    obj.expires_in = 0;
  }
  let expiresIn = obj.expires_in * 1000;
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      var value = obj;
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from cookie: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
  } else {
    return null
  }
 
}
