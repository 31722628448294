import store from "@/store";
export const realTime = {
  state: {
    data: [],
    time: undefined,
  },
  mutations: {
    SOCKET_status(state, data) {
      //console.log(store.state.auth.access);
      console.log(data);
      if (store.state.auth.access != null) {
        clearTimeout(state.time);

        state.time = setTimeout(readData, 500);
        state.data.push(data);

        function readData() {
          console.log(state.data);
          const data = state.data;
          state.time = undefined;
          state.data = [];
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            switch (data[i].type) {
              case "clear":
                store.commit("clearEvent", data[i].data);
                break;
              case "addEvent":
                store.commit("addEvent", data[i].data);
                break;
              case "deleteEvent":
                store.commit("deleteEvent", data[i].data);
                break;
              case "addReport":
                store.commit("addReport", data[i].data);
                break;
              case "updateReport":
                store.commit("updateReport", data[i].data);
                break;
              //building
              case "acknowledge":
                store.commit("updateAck", data[i].data);
                break;
              case "reset":
                store.commit("updateReset", data[i].data);
                break;
              case "silence":
                store.commit("updateSilence", data[i].data);
                break;
              case "drll":
                store.commit("updateDrll", data[i].data);
                break;
              case "sound":
                store.commit("updateSound", data[i].data);
                break;
              case "ioStatus":
                store.commit("updateIO", data[i].data);
                break;
              case "gatewayStatus":
                store.commit("updateGate", data[i].data);
                break;
              case "resetEvent":
                store.commit("resetEvent", data[i].data);
                break;
              case "resetReport":
                store.commit("resetReport", data[i].data);
                break;
              case "typeNumberEvent":
                store.commit("typeNumberEvent", data[i].data);
                break;
              case "typeNumberReport":
                store.commit("typeNumberReport", data[i].data);
                break;
              case "mapEvent":
                store.commit("mapEvent", data[i].data);
                break;
            }
          }
        }
      }
    },
  },
};
