import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "reports/";
import authHeader from "../services/auth-header";
export const reports = {
  state: {
    building: null,
    reports: [],
    report: {
      data: [],
      type: [
        {
          name: "Fire Alarm",
          value: 0,
        },
        {
          name: "Trouble",
          value: 0,
        },
        {
          name: "Disable",
          value: 0,
        },
        {
          name: "Bell",
          value: 0,
        },
        {
          name: "Activity",
          value: 0,
        },
      ],
    },
    export: [],
    infiniteHis: true,
    filterHis: ["Fire Alarm", "Trouble", "Bell", "Disable", "Activity"],
    lengthHis: null,
  },
  getters: {
    reportsBuilding: (state) => state.reports,
    reportBuilding: (state) => state.report,
    reportExport: (state) => state.export,
    infiniteHis: (state) => state.infiniteHis,
  },
  actions: {
    clearHis({ commit }) {
      commit("clearHis");
    },
    clearReport({ commit }) {
      commit("clearReport");
    },
    clearHisForType({ commit }) {
      commit("clearHisForType");
    },
    getReports({ commit }) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          commit("getReports", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportByID({ commit }, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            lastNumber: data.last,
            length: data.length,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res.data.data);
          commit("setID", data.id);
          commit("getReport", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportByType({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "filter/" + data.id,
          {
            filter: data.filter,
            lastNumber: data.last,
            length: data.length,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res.data.data);
          commit("getReport", res.data.data);
          commit("setFilterHis", data.filter);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportBySearch({ commit }, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "search",
          {
            building: data.id,
            company: data.company,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("getReportBySearch", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportExport({ commit }, data) {
      return axios
        .post(
          apiURL + "export",
          {
            building: data.id,
            company: data.company,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getReportExport", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mutations: {
    setFilterHis(state, data) {
      state.filterHis = data;
    },
    clearHisForType(state) {
      state.report.data = [];
    },
    clearReport(state) {
      state.reports = [];
      state.export = [];
    },
    clearHis(state) {
      state.report = {
        data: [],
        type: [
          {
            name: "Fire Alarm",
            value: 0,
          },
          {
            name: "Trouble",
            value: 0,
          },
          {
            name: "Disable",
            value: 0,
          },
          {
            name: "Bell",
            value: 0,
          },
          {
            name: "Activity",
            value: 0,
          },
        ],
      };
    },
    getReport(state, report) {
      for (let i = 0; i < report.length; i++) {
        state.report.data.push(report[i]);
      }
      if (report.length == 0) {
        console.log("false");
        state.infiniteHis = false;
      } else {
        console.log("true");
        state.infiniteHis = true;
      }
    },
    typeNumberReport(state, data) {
      if (data.building == state.building) {
        state.report.type = data.type;
      }
    },
    getReportBySearch(state, reportBySearch) {
      state.reports = reportBySearch;
    },
    getReportByType(state, report) {
      state.report.data = report;
    },
    getReports(state, reports) {
      state.reports = reports;
    },
    getReportExport(state, reports) {
      state.export = reports;
    },
    addReport(state, report) {
      console.log(report);
      console.log(state.report);
      if (state.building == report.building) {
        const index = state.filterHis.findIndex((f) => f == report.type);
        if (index > -1) {
          state.report.data.unshift(report);
        }

        const indexType = state.report.type.findIndex(
          (e) => e.name === report.type
        );
        console.log(indexType)
        if (indexType > -1) {
          state.report.type[indexType].value++;
          console.log(state.report.type[indexType]);
        } else {
          state.report.type.push({
            name: report.type,
            value: 1,
          });
        }
      }
    },
    updateReport(state, data) {
      if (state.report.length > 0) {
        const index = state.report.findIndex((c) => c.id === data.id);
        if (index > -1) {
          state.report.splice(index, 1);
        }
      }
    },
    setID(state, id) {
      state.building = id;
    },
    resetReport(state, data) {
      console.log(data)
      let lengthReport = 10;
      if (state.building == data.building) {
        if (state.filterHis.length != 5) {
          if (state.report.data.length < 100) {
            lengthReport = state.report.data.length + 1;
            axios
              .put(
                apiURL + "filter/" + state.building,
                {
                  filter: state.filterHis,
                  lastNumber: 0,
                  length: state.report.data.length,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                console.log(res.data.data);
                state.report.data = res.data.data;
                state.infiniteHis = true;
                if (res.data.data.length == 0) {
                  state.infiniteHis = false;
                } else {
                  state.infiniteHis = true;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            axios
              .put(
                apiURL + "filter/" + state.building,
                {
                  filter: state.filter,
                  lastNumber: 0,
                  length: 10,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                console.log(res.data.data);
                state.report.data = res.data.data;
                state.infiniteHis = true;
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } 
      }
        
    },
  },
};
