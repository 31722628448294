<template>
  <v-container fluid>
    <v-card class="ma-1">
      <v-card-title>
        Config
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getConfig"
        :items-per-page="5"
      >
       
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="editConfigData(item)"
            class="success elevation-5 mr-2"
            icon
          >
            <v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn class="error elevation-5" @click="delData(item._id)" icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-btn
        style="position: fixed; bottom: 25px; right: 0px"
        class="ma-10"
        fixed
        fab
        color="#D63636"
        dark
        absolute
        bottom
        right
        dense
        floating
        @click="form = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-dialog v-model="form" persistent max-width="600px">
        <template>
          <v-card>
            <v-card-title
              ><span class="text-h4">Create Config</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="addConfig()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="End With"
                    label="End With*"
                    v-model="configData.endWith"
                    :rules="[(v) => !!v || 'End With is required']"
                    required
                  ></v-text-field>

                  <v-text-field
                    name="ModuleFormat"
                    label="Module Format*"
                    v-model="configData.moduleFormat"
                    :rules="[(v) => !!v || 'Module Format is required']"
                    required
                  ></v-text-field>

                  <v-text-field
                    name="Time Format"
                    label="Time Format*"
                    v-model="configData.timeFormat"
                    :rules="[(v) => !!v || 'Time Format is required']"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn id="btnsave" text @click="addConfig()"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="editform" persistent max-width="600px">
        <template>
          <v-card>
            <v-card-title
              ><span class="text-h5">Edit Config</span></v-card-title
            >
            <v-card-text>
              <v-form
                @submit.prevent="editConfig()"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="End With"
                    label="End With*"
                    v-model="configDataEdit.endWith"
                    :rules="[(v) => !!v || 'End With is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    name="ModuleFormat"
                    label="Module Format*"
                    v-model="configDataEdit.moduleFormat"
                    :rules="[(v) => !!v || 'Module Format is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    name="Time Format"
                    label="Time Format*"
                    v-model="configDataEdit.timeFormat"
                    :rules="[(v) => !!v || 'Time Format is required']"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn id="btnsave" text @click="editConfig()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="confirmDelete" max-width="300">
        <v-card>
          <v-card-title primary-title> Confirm Delete </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this config ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDlg()"> Cancel </v-btn>

            <v-btn color="error" text @click="delConfig()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "user",
  data: () => ({
    id: "",
    idDle: "",
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    resetPW: false,
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    headers: [
      { text: "End With", value: "endWith", sortable: true },
      { text: "Module Format", value: "moduleFormat", sortable: true },
      { text: "Time Format", value: "timeFormat", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    configData: {
      building: "",
      endWith: "",
      moduleFormat: "",
      timeFormat: "",
    },
    configDataEdit: {
      endWith: "",
      moduleFormat: "",
      timeFormat: "",
      building: ""
    },
  }),
  computed: {
    getConfig() {
      console.log(this.$store.getters.allConfigs)
      return this.$store.getters.allConfigs;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser || this.getAccess.username != "pmAdmin") {
      this.$router.push("/");
    } else {
      this.id = Cookies.get("idBuilding");
      this.$store.dispatch("getConfigs", this.id);
      //this.$store.dispatch("getBuildingsWithCompany", "");
    }
  },
  methods: {
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.configData.building = "";
      this.configData.endWith = "";
      this.configData.moduleFormat = "";
      this.configData.timeFormat = "";
    },
    delData(id) {
      this.confirmDelete = true;
      this.idDle = id;
    },
    addConfig() {
      console.log(this.configData);
      this.configData.building = this.id;
      this.$store
        .dispatch("createConfig", this.configData)
        .then((res) => {
          this.closeDlg();
          this.color = "success";
          this.text = "Config has been successfully saved.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config.";
          this.snackbar = true;
        });
    },
    delConfig() {
      console.log(this.idDle)
      this.$store
        .dispatch("deleteConfig", this.idDle)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Config has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config.";
          this.snackbar = true;
        });
    },
    editConfigData(data) {
      console.log(data);
      //console.log("data");
      this.editform = true;
      this.configDataEdit = Object.assign({}, data);
    },
    editConfig() {
      console.log(this.configDataEdit);

      this.$store
        .dispatch("editConfig", this.configDataEdit)
        .then((res) => {
          //console.log('res.error.message');
          console.log(res);
           this.closeDlg();
          this.color = "success";
          this.text = "Success! Config has been edited.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this config.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>
