import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "lines/";
import authHeader from "../services/auth-header";
export const lines = {
  state: {
    linesBuilding: [],
    linesCom: [],
  },
  getters: {
    allLinesCom: (state) => state.linesCom,
    allLinesBuilding: (state) => state.linesBuilding,
  },
  mutations: {
    setLines(state, lines) {
      state.lines = lines;
    },
    setLinesBuilding(state, lines) {
      state.linesBuilding = lines;
    },
    setLinesCom(state, lines) {
      state.linesCom = lines;
    },
    createLineBuilding(state, line) {
      state.linesBuilding.unshift(line);
    },
    updateLineBuilding(state, line) {
      
      const index = state.linesBuilding.findIndex((u) => u.id === line.id);
      state.linesBuilding.splice(index, 1, line);
    },
    deleteLineBuilding(state, id) {
      
      const index = state.linesBuilding.findIndex((u) => u._id === id);
      state.linesBuilding.splice(index, 1);
    },
    createLineCom(state, line) {
      line.id = line._id;
      state.linesCom.unshift(line);
    },
    updateLineCom(state, line) {
      
      const index = state.linesCom.findIndex((u) => u._id === line._id);
      state.linesCom.splice(index, 1, line);
    },
    deleteLineCom(state, id) {
      
      const index = state.linesCom.findIndex((u) => u._id === id);
      state.linesCom.splice(index, 1);
    },
  },
  actions: {
    async getLinesCom({commit}, id) {
      return axios
        .get(apiURL + "companies/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          commit("setLinesCom", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async getLinesBuilding({ commit }, id) {
      return axios
        .get(apiURL + "buildings/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          commit("setLinesBuilding", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    createLineCom({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL + "companies/",
          {
            company: data.company,
            token: data.token,
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("createLineCom", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    createLineBuilding({ commit }, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "buildings/",
          {
            building: data.building,
            token: data.token,
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("createLineBuilding", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    deleteLineCom({ commit }, id) {
      return axios
        .delete(apiURL + "companies/" + id, { headers: authHeader() })
        .then((res) => {
          //console.log("Delete is successfully");
          commit("deleteLineCom", id);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    deleteLineBuilding({ commit }, id) {
      return axios
        .delete(apiURL + "buildings/" + id, { headers: authHeader() })
        .then((res) => {
          //console.log("Delete is successfully");
          commit("deleteLineBuilding", id);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    editLineCom({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "companies/" + data._id,
          {
            company: data.company,
            token: data.token,
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          commit("updateLineCom",data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
  },
};
