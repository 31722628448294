<template>
  <v-container fluid>
    <v-card class="ma-1">
      <v-card-title>
        Accounts
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getUsers"
        :items-per-page="5"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="!(getAccess.role === 'Admin' && item.role == 'Superadmin')"
            @click="resetPasswordData(item._id)"
            class="orange elevation-5 mr-2"
            icon
            ><v-icon>mdi-key</v-icon></v-btn
          >
          <v-btn
            v-if="!(getAccess.role === 'Admin' && item.role == 'Superadmin')"
            @click="editUserData(item)"
            class="success elevation-5 mr-2"
            icon
          >
            <v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            v-if="!(getAccess.role === 'Admin' && item.role == 'Superadmin')"
            class="error elevation-5"
            @click="delUserData(item._id)"
            icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-btn
        style="position: fixed; bottom: 25px; right: 0px"
        class="ma-10"
        fixed
        fab
        color="#D63636"
        dark
        absolute
        bottom
        right
        dense
        floating
        @click="form = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div id="Form">
        <v-dialog v-model="form" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h4">Create account</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addUser()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="userData.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                    ></v-text-field>

                    <v-text-field
                      name="username"
                      label="Username*"
                      v-model="userData.username"
                      :rules="[(v) => !!v || 'Username is required']"
                      required
                      @keypress="isLetter($event)"
                    ></v-text-field>

                    <v-text-field
                      :type="hidePassword ? 'password' : 'text'"
                      :append-icon="
                        hidePassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                      "
                      name="password"
                      label="Password*"
                      v-model="userData.password"
                      @click:append="hidePassword = !hidePassword"
                      :rules="[(v) => !!v || 'Password is required']"
                      required
                      @keypress="isLetter($event)"
                    ></v-text-field>

                    <v-select
                      :items="cheackItem()"
                      name="role"
                      label="Role*"
                      item-value="id"
                      v-model="userData.role"
                      required
                    ></v-select>

                    <v-select
                      v-if="getAccess.role === 'Superadmin'"
                      :items="getCompanies"
                      :item-text="'name'"
                      :item-value="'_id'"
                      name="company"
                      label="Company*"
                      v-model="userData.company._id"
                      required
                    ></v-select>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" text @click="addUser()"> Submit </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div id="editForm">
        <v-dialog v-model="editform" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Edit Profile</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editUser()"
                  ref="userDataEdit"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="userDataEdit.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                    ></v-text-field>
                    <v-text-field
                      name="username"
                      label="Username*"
                      v-model="userDataEdit.username"
                      :rules="[(v) => !!v || 'Username is required']"
                      required
                    ></v-text-field>

                    <v-select
                      :items="cheackItem()"
                      name="role"
                      label="Role*"
                      item-value="id"
                      v-model="userDataEdit.role"
                      @keypress="isLetter($event)"
                      required
                    ></v-select>

                    <v-select
                      :items="getCompanies"
                      :item-text="'name'"
                      :item-value="'_id'"
                      name="company"
                      label="Company*"
                      v-model="userDataEdit.company._id"
                      required
                    ></v-select>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn
                  id="btnsave"
                  text
                  @click="
                    editUser();
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div>
        <v-dialog v-model="resetPW" max-width="400px">
          <v-card>
            <v-card-title primary-title> Reset password</v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="resetPasswords()"
                ref="userData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-text-field
                  :type="hideNewPassword ? 'password' : 'text'"
                  :append-icon="
                    hideNewPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                  "
                  name="new password"
                  label="New Password"
                  hide-details="auto"
                  v-model="password.new"
                  @click:append="hideNewPassword = !hideNewPassword"
                  :rules="[(v) => !!v || 'New Password is required']"
                  required
                  @keypress="isLetter($event)"
                ></v-text-field>
                <v-text-field
                  :type="hideConfirmPassword ? 'password' : 'text'"
                  :append-icon="
                    hideConfirmPassword ? 'mdi-eye-off' : 'mdi-eye-outline'
                  "
                  name="confirm password"
                  label="Confirm Password"
                  hide-details="auto"
                  v-model="password.confirm"
                  @click:append="hideConfirmPassword = !hideConfirmPassword"
                  :rules="[(v) => !!v || 'Confirm Password is required']"
                  required
                  @keypress="isLetter($event)"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>
              <v-btn id="btnsave" text @click="resetPasswords()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div id="CFDel">
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this account ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delUser()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "user",
  data: () => ({
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    resetPW: false,
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    headers: [
      { text: "Username", value: "username", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Role", value: "role", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    userData: {
      name: "",
      username: "",
      password: "",
      role: "",
      company: {
        _id: "",
        name: "",
      },
    },
    userDataEdit: {
      name: "",
      username: "",
      role: "",
      company: {
        _id: "",
        name: "",
      },
    },
    resetData: {
      id: "",
      newPassword: "",
    },
    password: {
      id: "",
      new: "",
      confirm: "",
    },
    itemAdmin: ["User", "Admin", "AdminDrill"],
    itemSuperadmin: ["User", "Admin", "AdminDrill"],
  }),
  computed: {
    getUsers() {
      let user = this.$store.getters.allUsers;

      if (this.getAccess.username != "pmAdmin") {
        for (let i = user.length - 1; i >= 0; i--) {
          if (user[i].role === "Superadmin") {
            user.splice(i, 1);
          }
        }
      }
      return user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role != "Superadmin") {
      this.$router.push("/");
    } else {
      if (this.getAccess.role === "Superadmin") {
        this.headers.splice(3, 0, {
          text: "Company",
          value: "company.name",
          sortable: true,
        });
      }
      this.loadUser();
    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    cheackItem() {
      if (this.getAccess.role === "Superadmin") {
        return this.itemSuperadmin;
      } else {
        return this.itemAdmin;
      }
    },
    loadUser() {
      this.$store.dispatch("getUsers");
      this.$store.dispatch("getCompanies");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.resetPW = false;
      this.userData.name = "";
      this.userData.username = "";
      this.userData.password = "";
      this.userData.role = "";
      this.password.new = "";
      this.password.confirm = "";
      this.userData.company._id = "";
      this.userData.company.name = "";
    },
    delUserData(id) {
      this.confirmDelete = true;
      this.userDelId = id;
    },
    addUser() {
      if (
        this.getAccess.role == "Admin" ||
        this.getAccess.role == "AdminDrill"
      ) {
        this.userData.company._id = this.getAccess.company;
      }
      if (
        this.userData.name === "" ||
        this.userData.username === "" ||
        this.userData.password === "" ||
        this.userData.role === "" ||
        this.userData.company._id === ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill up this form for create an account.";
      } else {
        const index = this.getCompanies.findIndex(
          (u) => u._id === this.userData.company._id
        );
        this.userData.company.name = this.getCompanies[index].name;
        this.$store.dispatch("createUsers", this.userData).then((res) => {
          if (res.status === 200) {
            this.closeDlg();
            this.color = "success";
            this.text = "Account has been successfully saved.";
            this.snackbar = true;
          } else {
            this.snackbar = true;
            this.color = "error";
            this.text = res;
          }
        });
      }
    },
    delUser() {
      this.$store
        .dispatch("deleteUser", this.userDelId)
        .then(() => {
          this.closeDlg();
          this.color = "success";
          this.text = "Deleted! Account has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this account.";
          this.snackbar = true;
        });
    },
    editUserData(data) {
      console.log(data);
      //console.log("data");
      this.editform = true;
      this.userDataEdit = Object.assign({}, data);
    },
    editUser() {
      console.log(this.userDataEdit);
      const index = this.getCompanies.findIndex(
        (u) => u._id === this.userDataEdit.company._id
      );
      this.userDataEdit.company.name = this.getCompanies[index].name;
      this.$store
        .dispatch("editUsers", this.userDataEdit)
        .then((res) => {
          //console.log('res.error.message');
          console.log(res);
          if (res.data.message == "Sorry Username Is Already Exists") {
            //console.log("error");
            this.color = "error";
            this.text = " Error! Sorry,that username already exists!";
            this.snackbar = true;
          } else {
            this.color = "success";
            this.text = "Success! Account has been edited.";
            this.snackbar = true;
            this.closeDlg();
          }
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot edit this account.";
          this.snackbar = true;
        });
    },
    resetPasswordData(id) {
      this.resetPW = true;
      this.password.id = id;
    },
    resetPasswords() {
      if (this.password.new == "" || this.password.confirm == "") {
        this.text = "Error! Please fill out this form.";
        this.color = "red";
        this.snackbar = true;
      } else {
        if (this.password.new === this.password.confirm) {
          this.resetData.id = this.password.id;
          this.resetData.newPassword = this.password.new;
          //console.log(this.resetData);
          this.$store
            .dispatch("resetPassword", this.resetData)
            .then(() => {
              this.closeDlg();
              this.color = "success";
              this.text = "Reset Password Complete.";
              this.resetPW = false;
              this.snackbar = true;
            })
            .catch((error) => {
              console.log(error);
              this.password.new = "";
              this.password.confirm = "";
              this.color = "error";
              this.text = " Error! You cannot reset password this account.";
              this.snackbar = true;
            });
        } else {
          this.color = "error";
          this.text = " Error! Passwords do not match!";
          this.snackbar = true;
        }
      }
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>