<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-card id="card" class="mx-6 mb-10">
        <v-card-title>
          Line Company
          <v-spacer></v-spacer>
          <v-text-field
            ref="search"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="shrink"
            style="width: 300px"
            color="#D32F2F"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          item-key="id"
          :search="search"
          :headers="headers"
          :items="getLines"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isMobile">
            <v-btn
              @click="editData(item)"
              class="success elevation-5 mr-2"
              icon
            >
              <v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn
              @click="delData(item._id)"
              class="error elevation-5 mr-2"
              icon
            >
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-else>
            <v-btn fab @click="editData(item)" color="success" icon>
              <v-icon>mdi-pencil</v-icon></v-btn
            >

            <v-btn fab @click="delData(item._id)" color="error" icon>
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          style="position: fixed; right: 0px"
          :style="isMobile || isApp ? 'bottom: 60px;' : 'bottom: 5px;'"
          class="mx-8"
          fixed
          fab
          color="#D32F2F"
          dark
          absolute
          bottom
          right
          dense
          floating
          @click="form = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog v-model="form" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Manual Add Line</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addLine()"
                  @keyup.native.enter="addLine()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="Line Name"
                      label="Line Name*"
                      :rules="[(v) => !!v || 'Line Name is required']"
                      required
                      v-model="lineData.name"
                      color="#000"
                    ></v-text-field>
                    <v-text-field
                      name="Token"
                      label="Token*"
                      :rules="[(v) => !!v || 'Token is required']"
                      required
                      v-model="lineData.token"
                      color="#000"
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="addLine()">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="editForm" persistent max-width="500px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Edit Config Line</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editLine()"
                  @keyup.native.enter="editLine()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="Line Name"
                      label="Line Name*"
                      :rules="[(v) => !!v || 'Line Name is required']"
                      required
                      v-model="lineData.name"
                      color="#000"
                    ></v-text-field>
                    <v-text-field
                      name="Token"
                      label="Token*"
                      :rules="[(v) => !!v || 'Token is required']"
                      required
                      v-model="lineData.token"
                      color="#000"
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" color="success" text @click="editLine()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this config line ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delLine()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <v-snackbar v-model="snackbar" top right :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      id: null,
      isMobile: false,
      isApp: false,
      form: false,
      editForm: false,
      search: null,
      lineData: {
        _id: null,
        company: null,
        token: null,
        name: null,
      },
      idDelete: null,
      color: null,
      text: null,
      snackbar: false,
      confirmDelete: false,
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Token", value: "token", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  created() {
    console.log(this.$route.params.data);
    if (this.$route.params.data != null) {
      this.id = this.$route.params.data._id;
      Cookies.set("idCompany", this.id);
    }
  },
  computed: {
    getLines() {
      return this.$store.getters.allLinesCom;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    console.log(this.getAccess);
    if (!this.currentUser || this.getAccess.role == "User") {
      this.$router.push("/");
    } else {
      this.id = Cookies.get("idCompany");
      this.loadLine();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadLine() {
      if (this.getAccess.role == "Superadmin") {
        this.id = Cookies.get("idCompany");
      } else {
        this.id = this.getAccess.company;
      }
      this.$store.dispatch("getLinesCom", this.id);
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editForm = false;
      this.lineData.name = "";
      this.lineData.token = "";
    },
    addLine() {
      if (this.getAccess.role == "Superadmin") {
        this.lineData.company = Cookies.get("idCompany");
      } else {
        this.lineData.company = this.getAccess.company;
      }
      console.log(this.lineData);
      if (this.lineData.name == "" || this.lineData.token == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("createLineCom", this.lineData)
          .then((res) => {
            if (res.status == 200) {
              this.closeDlg();

              this.color = "success";
              this.text = "Config Line  has been successfully saved.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot create config line .";
          });
      }
    },
    editLine() {
      console.log(this.lineData);
      if (this.lineData.name == "" || this.lineData.token == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else {
        this.$store
          .dispatch("editLineCom", this.lineData)
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
              this.editForm = false;
              this.color = "success";
              this.text = "Success! Config Line has been edited.";
              this.snackbar = true;
            } else if (res.status == 400) {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          })
          .catch((error) => {
            this.color = "error";
            this.text = " Error! You cannot edit this config Line.";
            this.snackbar = true;
          });
      }
    },
    delData(id) {
      console.log(id);
      this.confirmDelete = true;
      this.idDelete = id;
    },
    editData(data) {
      console.log(data);
      this.editForm = true;
      this.lineData = Object.assign({}, data);
      console.log(this.lineData);
    },
    delLine() {
      this.$store
        .dispatch("deleteLineCom", this.idDelete)
        .then((res) => {
          if (res.status == 200) {
            this.closeDlg();

            this.color = "success";
            this.text = "Deleted! Config Line has been deleted.";
            this.snackbar = true;
          } else if (res.status == 400) {
            this.snackbar = true;
            this.color = "error";
            this.text = res.data.error.message;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this config Line.";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style></style>
