<template>
  <v-container fluid>
    <v-card class="ma-1">
      <v-card-title>
        Company
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="shrink"
          style="width: 300px"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getCompanies"
        :items-per-page="5"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            :to="{ name: 'LineCompanies', params: { data: item } }"
            v-if="getAccess.role === 'Superadmin'"
          >
            <v-btn class="success elevation-5 mr-2" icon>
              <font-awesome-icon
                icon="fa-brands fa-line fa-lg"
                style="font-size: 22px"
              />
            </v-btn>
          </router-link>
          <v-btn
            @click="editCompanyData(item)"
            class="info elevation-5 mr-2"
            icon
          >
            <v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            class="error elevation-5"
            @click="delCompanyData(item._id)"
            icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-btn
        style="position: fixed; bottom: 25px; right: 0px"
        class="ma-10"
        fixed
        fab
        color="#D63636"
        dark
        absolute
        bottom
        right
        dense
        floating
        @click="form = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div id="Form">
        <v-dialog v-model="form" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h4">Create Company</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="addCompany()"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="companyData.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn id="btnsave" text @click="addCompany()"> Submit </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div id="editForm">
        <v-dialog v-model="editform" persistent max-width="600px">
          <template>
            <v-card>
              <v-card-title
                ><span class="text-h5">Edit Profile</span></v-card-title
              >
              <v-card-text>
                <v-form
                  @submit.prevent="editCompany()"
                  ref="companyDataEdit"
                  lazy-validation
                  enctype="multipart/form-data"
                  autocomplete="off"
                >
                  <v-container fluid>
                    <v-text-field
                      name="name"
                      label="Name*"
                      v-model="companyDataEdit.name"
                      :rules="[(v) => !!v || 'Name is required']"
                      required
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDlg()"> Cancel </v-btn>
                <v-btn
                  id="btnsave"
                  text
                  @click="
                    editCompany();
                    closeDlg();
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div id="CFDel">
        <v-dialog v-model="confirmDelete" max-width="300">
          <v-card>
            <v-card-title primary-title> Confirm Delete </v-card-title>

            <v-card-text class="body">
              Are you sure to delete this Company ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDlg()"> Cancel </v-btn>

              <v-btn color="error" text @click="delCompany()"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "company",
  data: () => ({
    hidePassword: true,
    hideNewPassword: true,
    hideConfirmPassword: true,
    editform: false,
    snackbar: false,
    text: "",
    color: "",
    form: false,
    search: "",
    confirmDelete: false,
    headers: [
      { text: "Name", value: "name", sortable: true },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    companyData: {
      name: "",
    },
    companyDataEdit: {
      name: "",
    },
  }),
  computed: {
    getCompanies() {
      return this.$store.getters.allCompanies;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    if (this.getAccess.role === "Superadmin") {
      this.loadCompany();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    loadCompany() {
      this.$store.dispatch("getCompanies");
    },
    closeDlg() {
      this.form = false;
      this.confirmDelete = false;
      this.editform = false;
      this.companyData.name = "";
    },
    delCompanyData(id) {
      this.confirmDelete = true;
      this.companyDelId = id;
    },
    addCompany() {
      if (this.companyData.name === "") {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please fill in this form for create an Company.";
      } else {
        this.$store
          .dispatch("createCompanies", this.companyData)
          .then((res) => {
            if (res.status === 200) {
              this.closeDlg();

              this.color = "success";
              this.text = "Company has been successfully saved.";
              this.snackbar = true;
            } else {
              this.snackbar = true;
              this.color = "error";
              this.text = res.data.error.message;
            }
          });
      }
    },
    delCompany() {
      this.$store
        .dispatch("deleteCompanies", this.companyDelId)
        .then(() => {
          this.closeDlg();

          this.color = "success";
          this.text = "Deleted! Company has been deleted.";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
          this.color = "error";
          this.text = " Error! You cannot delete this Company.";
          this.snackbar = true;
        });
    },
    editCompanyData(data) {
      this.editform = true;
      this.companyDataEdit = Object.assign({}, data);
    },
    editCompany() {
      console.log(this.companyDataEdit);
      this.$store
        .dispatch("editCompanies", this.companyDataEdit)
        .then((res) => {
          console.log(res.response);
          if (res.response) {
            this.snackbar = true;
            this.color = "error";
            this.text = res.response.data.error.message;
          } else {
            this.closeDlg();
            this.color = "success";
            this.text = "Success! Company has been edited.";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.color = "error";
          this.text = res;
        });
    },
  },
};
</script>

<style>
#btnsave:hover {
  color: green;
}
.add {
  position: fixed;
  bottom: 500px;
  right: 20px;
}
</style>