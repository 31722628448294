import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "settings/";
import authHeader from "../services/auth-header";
export const settings = {
  actions: {
    setBell({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL + "setBell",
          {
            building: data.id,
            status: data.status,
            buildingName: data.buildingName,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    setAcknowledge({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL + "setAcknowledge",
          {
            building: data.id,
            status: data.status,
            buildingName: data.buildingName,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    setReset({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL + "setReset",
          {
            building: data.id,
            status: data.status,
            buildingName: data.buildingName,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    setDrill({ commit }, data) {
      //console.log(data);
      return axios
        .post(
          apiURL + "setDrll",
          {
            building: data.id,
            buildingName: data.buildingName,
            passwordBuilding: data.passwordBuilding,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
  },
};
