import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "events/";
import authHeader from "../services/auth-header";
import store from "@/store";
export const events = {
  state: {
    building: null,
    company: null,
    events: {
      data: [],
      type: [
        {
          name: "Fire Alarm",
          value: 0,
        },
        {
          name: "Trouble",
          value: 0,
        },
        {
          name: "Disable",
          value: 0,
        },
        {
          name: "Bell",
          value: 0,
        },
        {
          name: "Activity",
          value: 0,
        },
      ],
    },
    allPoint: [],
    allEvent: {
      event: [],
      number: 0,
    },
    loading: 2,
    infiniteEvent: true,
    infiniteAllEvent: true,
    filterEvent: ["Fire Alarm", "Trouble", "Bell", "Disable", "Activity"],
  },
  getters: {
    eventsBuilding: (state) => state.events,
    allEvent: (state) => state.allEvent,
    getScale: (state) => state.loading,
    getShowPoint: (state) => state.showPoint,
    infiniteEvent: (state) => state.infiniteEvent,
    infiniteAllEvent: (state) => state.infiniteAllEvent,
    getAllPonit: (state) => state.allPoint,
  },
  actions: {
    clearInfinite({ commit }) {
      commit("clearInfinite");
    },
    clearEvent({ commit }) {
      commit("clearEventInfinite");
    },
    clearEventForType({ commit }) {
      commit("clearEventForType");
    },
    setClear({ commit }, data) {
      console.log(data);
      return axios
        .post(
          apiURL + "clear",
          {
            building: data.id,
            passwordBuilding: data.passwordBuilding,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          commit("clearEvent", data.id);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getEvents({ commit }, data) {
      //console.log("getEvents");
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            lastNumber: data.last,
            length: data.length,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          //state.building = id;

          commit("setData", data);
          commit("getEvents", res.data.data, data);
          commit("setShow");
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.state.events.infiniteEvent = false;
        });
    },
    getAllEvents({ commit }, data) {
      return axios
        .post(
          apiURL + "notification",
          {
            lastNumber: data.last,
            length: data.length,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getAllEvents", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEventByType({ commit }, data) {
      //console.log(data);
      return axios
        .put(
          apiURL + "filter/" + data.id,
          {
            filter: data.filter,
            lastNumber: data.last,
            length: data.length,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("setData", data);
          commit("setFilter", data.filter);
          commit("getEvents", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mutations: {
    setFilter(state, data) {
      state.filterEvent = data;
    },
    clearInfinite(state) {
      state.infiniteEvent = true;
      store.state.reports.infiniteHis = true;
      state.filterEvent = [
        "Fire Alarm",
        "Trouble",
        "Bell",
        "Disable",
        "Activity",
      ];
      store.state.reports.filterHis = [
        "Fire Alarm",
        "Trouble",
        "Bell",
        "Disable",
        "Activity",
      ];

      store.state.reports.lengthHis = null;
    },
    clearEventInfinite(state) {
      state.events = {
        data: [],
        type: [
          {
            name: "Fire Alarm",
            value: 0,
          },
          {
            name: "Trouble",
            value: 0,
          },
          {
            name: "Disable",
            value: 0,
          },
          {
            name: "Bell",
            value: 0,
          },
          {
            name: "Activity",
            value: 0,
          },
        ],
      };
    },
    clearEventForType(state) {
      state.events.data = [];
    },
    getEvents(state, events) {
      for (let i = 0; i < events.length; i++) {
        state.events.data.push(events[i]);
        //console.log(state.events);
      }
      if (events.length == 0) {
        state.infiniteEvent = false;
        //console.log(state.infiniteEvent);
      } else {
        state.infiniteEvent = true;
        //console.log(state.infiniteEvent);
      }
    },
    typeNumberEvent(state, data) {
      if (data.building == state.building) {
        state.events.type = data.type;
      }
      if (store.state.buildings.buildings.building.length > 0) {
        const indexBuild = store.state.buildings.buildings.building.findIndex(
          (c) => c.id === data.building
        );

        if (indexBuild > -1) {
          for (let i = 0; i < data.type.length; i++) {
            let textType = data.type[i].name.toLowerCase();
            if (textType == "fire alarm") {
              textType = "fireAlarm";
            }

            store.state.buildings.buildings.building[indexBuild][textType] =
              data.type[i].value;
          }
        }
      }
    },
    getAllEvents(state, allEvent) {
      //console.log(allEvent);
      state.allEvent.number = allEvent.number;
      for (let i = 0; i < allEvent.event.length; i++) {
        state.allEvent.event.push(allEvent.event[i]);
        //console.log(state.allEvent);
      }
      if (allEvent.event.length == 0) {
        state.infiniteAllEvent = false;
      } else {
        state.infiniteAllEvent = true;
      }
    },
    addEvent(state, event) {
      // clearTimeout(state.time);
      // state.loading = 0;
      //console.log(state.building);
      //console.log(store.state.auth.access);
      // state.time = setTimeout(() => {
      //   console.log("last")
      //   state.loading = 2;
      // }, 2000);
      //console.log(state.filterEvent);
      if (store.state.auth.access.role == "Superadmin") {
        state.allEvent.event.unshift(event);
        state.allEvent.number++;
      } else if (store.state.auth.access.company == event.company) {
        state.allEvent.event.unshift(event);
        state.allEvent.number++;
      }
      if (event.building == state.building) {
        const index = state.filterEvent.findIndex((f) => f == event.type);
        //console.log(index);
        if (index > -1) {
          state.events.data.unshift(event);
          state.allPoint.push(event);
        }

        const indexType = state.events.type.findIndex(
          (e) => e.name === event.type
        );
        if (indexType > -1) {
          state.events.type[indexType].value++;
        } else {
          state.events.type.push({
            name: event.type,
            value: 1,
          });
        }
      }
      if (store.state.buildings.buildings.building.length > 0) {
        const indexBuild = store.state.buildings.buildings.building.findIndex(
          (c) => c.id === event.building
        );
        if (indexBuild > -1) {
          let textType = event.type.toLowerCase();
          if (event.type == "Fire Alarm") {
            textType = "fireAlarm";
          }
          store.state.buildings.buildings.building[indexBuild][textType]++;
        }
      }
    },
    deleteEvent(state, data) {
      /* console.log(data);
      console.log(state.events);
      clearTimeout(state.time);
      state.loading = 0;
      //console.log(state.building);
      //console.log(store.state.auth.access);
      state.time = setTimeout(() => {
        console.log("last");
        state.loading = 2;
      }, 2000); */
      //console.log(state.allEvent);
      //console.log(data);

      if (state.allEvent.event.length > 0) {
        const indexAll = state.allEvent.event.findIndex(
          (c) => c._id === data._id
        );
        if (indexAll > -1) {
          state.allEvent.event.splice(indexAll, 1);
          state.allEvent.number--;
        }
      }
      if (state.events.data.length > 0) {
        //console.log(state.events.data);
        const index = state.events.data.findIndex((e) => e._id === data._id);
        //console.log(index);
        if (index > -1) {
          state.events.data.splice(index, 1);
          const indexType = state.events.type.findIndex(
            (e) => e.name === data.type
          );
          //console.log(indexType);
          if (indexType > -1) {
            state.events.type[indexType].value--;
          }
        }
      }
      if (store.state.buildings.buildings.building.length > 0) {
        const indexBuild = store.state.buildings.buildings.building.findIndex(
          (c) => c.id === data.building
        );
        if (indexBuild > -1) {
          let textType = data.type.toLowerCase();
          store.state.buildings.buildings.building[indexBuild][textType]--;
        }
      }
    },
    clearEvent(state, data) {
      if (state.building == data.building) {
        state.events.data = [];
        state.events.type = [];
      }
      store.commit("stopSound", data.building);

      let lengthAll = 10;
      if (store.state.buildings.buildings.building.length > 0) {
        const indexBuild = store.state.buildings.buildings.building.findIndex(
          (c) => c.id === data.building
        );
        if (indexBuild > -1) {
          store.state.buildings.buildings.building[indexBuild].fireAlarm = 0;
          store.state.buildings.buildings.building[indexBuild].trouble = 0;
          store.state.buildings.buildings.building[indexBuild].bell = 0;
          store.state.buildings.buildings.building[indexBuild].disable = 0;
          store.state.buildings.buildings.building[indexBuild].activity = 0;
        }
      }
      if (
        store.state.auth.access.role == "Superadmin" ||
        (store.state.auth.access.role != "Superadmin" &&
          store.state.auth.access.company == data.company)
      ) {
        if (state.allEvent.event.length < 100) {
          lengthAll = state.allEvent.event.length;
        }
        axios
          .post(
            apiURL + "notification",
            {
              lastNumber: 0,
              length: lengthAll,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            //console.log(res.data.data);
            state.allEvent.number = res.data.data.number;

            state.allEvent.event = res.data.data.event;
            //console.log(state.allEvent);

            if (res.data.data.event.length == 0) {
              state.infiniteAllEvent = false;
            } else {
              state.infiniteAllEvent = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      //console.log(newArr);
    },
    setData(state, data) {
      state.building = data.id;
      //console.log(state.building);
    },
    setShow(state) {
      state.showPoint = true;
    },
    resetEvent(state, data) {
      //console.log(data);
      let lengthEvent = 10;
      let lengthAll = 10;
      if (state.building == data.building) {
        if (state.events.data.length < 100) {
          lengthEvent = state.events.data.length + 1;
        }
        axios
          .put(
            apiURL + "filter/" + state.building,
            {
              filter: state.filterEvent,
              lastNumber: 0,
              length: lengthEvent,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            //console.log(res.data.data);
            state.events.data = res.data.data;
            state.infiniteEvent = true;
            if (res.data.data.length == 0) {
              state.infiniteEvent = false;
            } else {
              state.infiniteEvent = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      console.log(store.state.auth.access);
      if (
        store.state.auth.access.role == "Superadmin" ||
        (store.state.auth.access.role != "Superadmin" &&
          store.state.auth.access.company == data.company)
      ) {
        if (state.allEvent.event.length < 100) {
          lengthAll = state.allEvent.event.length;
        }
        axios
          .post(
            apiURL + "notification",
            {
              lastNumber: 0,
              length: lengthAll,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            //console.log(res.data.data);
            state.allEvent.number = res.data.data.number;

            state.allEvent.event = res.data.data.event;
            //console.log(state.allEvent);

            if (res.data.data.event.length == 0) {
              state.infiniteAllEvent = false;
            } else {
              state.infiniteAllEvent = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    mapEvent(state, data) {
      if (state.building == data.building) {
        //console.log(state.filterEvent);
        //console.log(state.building);
        state.allPoint = [];
        for (let i = 0; i < state.filterEvent.length; i++) {
          const newArr = data.event.filter((object) => {
            return object.type == state.filterEvent[i];
          });
          //console.log(newArr);
          state.allPoint = state.allPoint.concat(newArr);
          //console.log(state.allPoint);
        }
      }
    },
  },
};
