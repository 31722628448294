import AuthService from "../services/auth.service";
import Cookies from "js-cookie";
import store from "@/store";
const user = Cookies.getJSON("user");

const initialSate = user
  ? { status: { loggedIn: true }, user, access: null }
  : { status: { loggedIn: false }, user: null, access: null };

export const auth = {
  namespaced: true,
  state: initialSate,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    resetPassword(_, payload) {
      return AuthService.resetPassword(payload).then(
        (response) => {
          //commit("ResetPassword", response.data);
          //console.log(response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changePassword(_, payload) {
      return AuthService.changePassword(payload).then(
        (response) => {
          //commit("ResetPassword", response.data);
          ///console.log(response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    decode({ commit }) {
      const jwt = AuthService.jwtDecode();
      //console.log(jwt)
      commit("decode", jwt);
    },
    active(_, payload) {
      return AuthService.active(payload).then(
        (response) => {
          //commit("ResetPassword", response.data);
          ///console.log(response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      //state.access = jwt_decode(user.accessToken);
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.access = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      store.state.buildings.buildings.building = [];
      store.state.buildings.buildings.sound = false;
      //state.access = null;
      //state.stations = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
      //state.users.unshift(user);
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    decode(state, payload) {
      state.access = payload;
    },
  },
};
