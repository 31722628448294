<template>
  <div>
    <v-container fluid>
      <v-card class="ma-1">
        <v-card-title>
          <v-row align="center">
            <v-col cols="12" lg="4" md="4" sm="3"> RealTime </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="5"
        ></v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    let dataBuilding = JSON.parse(Cookies.get("dataBuilding"));
    //console.log(dataBuilding)
    if(dataBuilding != null || dataBuilding != undefined){
      this.macAddress = dataBuilding.macAddress
    }
    this.sockets.subscribe("messageRealtime", (data) => {
      if(data.macAddress != null || data.macAddress != undefined){
        if(data.macAddress == this.macAddress){
          this.items.push(data);
        }
      }
    });
    
  },
  data() {
    return {
      macAddress: null,
      headers: [
        {
          text: "Date Time",
          align: "start",
          sortable: false,
          value: "time",
          width: "12%",
        },
        { text: "Mac Address", value: "macAddress", width: "40%" },
        { text: "Message", value: "message", width: "40%" },
      ],
      items: [],
    };
  },
  methods: {},
};
</script>
<style></style>
