<template>
  <div v-resize="onResize" v-if="getMaps">
    <v-container fluid>
      <h1>
        {{ this.$router.currentRoute.name }}
      </h1>
      <v-card id="card" class="mt-5">
        <v-row justify="center">
          <v-col class="ml-2">
            <v-select
              v-model="mapShow"
              dense
              :items="getMaps"
              :item-text="'name'"
              :item-value="'id'"
              label="Name Map"
              outlined
              hide-details
              @change="selectMap()"
            ></v-select>
          </v-col>
          <v-col align="end" class="mr-2">
            <v-btn
              dark
              small
              color="green"
              style="
                border-style: solid;
                border-right: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              "
              width="30px"
              @click="opendialog()"
            >
              <v-icon dark small>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              @click="editMap(getMaps[onboarding])"
              dark
              small
              color="orange"
              style="
                border-style: solid;
                border-right: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-left: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              "
              width="30px"
            >
              <v-icon dark small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              dark
              small
              color="red"
              style="
                border-style: solid;
                border-left: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              "
              width="30px"
              @click="delDataMap(getMaps[onboarding].id)"
            >
              <v-icon dark small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-col col="12" justify="center">
          <v-card style="position: relative; height: 100%; width: 100vw">
            <v-window v-model="onboarding" touchless>
              <v-window-item
                v-if="getMaps.length != 0"
                v-for="n in getMaps.length"
                :key="`card-${n}`"
              >
                <v-img
                  :id="getMaps[n - 1].id"
                  style="position: relative; width: 100vw; height: auto"
                  :src="getMaps[n - 1].photo"
                >
                  <div v-for="address in getAddersses" v-if="isShowPoint">
                    <div
                      style="position: relative"
                      :style="calPosition(address)"
                    >
                      <span
                        style="
                          top: -25px;
                          left: -20px;
                          position: absolute;
                          border-radius: 4px;
                          font-size: 15px;
                          background-color: azure;
                        "
                      >
                        {{ address.name }}
                      </span>
                      <v-avatar
                        size="22"
                        style="
                          position: absolute;

                          background: red;
                          cursor: pointer;
                        "
                        @click="editAddress(address)"
                      >
                        <v-icon dark small> mdi-access-point </v-icon>
                      </v-avatar>
                    </div>
                  </div>

                  <vue-drag-resize
                    v-if="editPoint"
                    :isResizable="false"
                    :w="22"
                    :h="22"
                    @dragstop="dragStop"
                    :parentLimitation="true"
                    :parentW="map.width"
                    :parentH="map.height"
                    :x="calPositionX(oldX)"
                    :y="calPositionY(oldY)"
                  >
                    <div style="position: relative">
                      <v-avatar size="22" color="orange">
                        <v-icon dark small> mdi-access-point </v-icon>
                      </v-avatar>
                    </div>
                    <span
                      style="
                        top: -25px;
                        left: -15px;
                        position: absolute;
                        border-radius: 4px;
                        font-size: 15px;
                        background-color: azure;
                      "
                    >
                      {{ addressData.name }}
                    </span>
                    <v-row style="position: absolute; top: 30px; left: -35px">
                      <v-col>
                        <v-btn
                          v-if="!isMobile"
                          small
                          icon
                          color="green"
                          @click="savePoint()"
                          style="background-color: black; z-index: 4; top: 0px"
                        >
                          <v-icon> mdi-check-bold </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          v-if="!isMobile"
                          small
                          icon
                          color="red"
                          @click="closeEditAdd()"
                          style="
                            background-color: black;
                            z-index: 4;
                            left: 70px;
                            top: -50px;
                          "
                        >
                          <v-icon> mdi-close-thick </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </vue-drag-resize>
                </v-img>
              </v-window-item>

              <v-img
                v-if="getMaps.length == 0"
                style="position: relative; width: 100%; object-fit: contain"
                src="https://awarethai.com/api/images/nopic.png"
              ></v-img>
            </v-window>
            <v-btn
              v-if="!editPoint && getMaps.length != 0"
              style="position: absolute; top: 1vh; left: 1vw"
              small
              fab
              color="#D63636"
              dark
              dense
              @click="openAdd()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              v-if="editPoint && isMobile"
              small
              fab
              dark
              color="white"
              @click="savePoint()"
              style="position: absolute; top: 1vh; left: 1vw"
            >
              <v-icon color="green"> mdi-check-bold </v-icon>
            </v-btn>
            <v-btn
              v-if="editPoint && isMobile"
              small
              fab
              dark
              color="white"
              @click="closeEditAdd()"
              style="
                background-color: black;

                top: 0px;
                position: absolute;
                top: 1vh;
                left: 65px;
              "
            >
              <v-icon color="red"> mdi-close-thick </v-icon>
            </v-btn>
          </v-card>
        </v-col>

        <v-card-actions class="justify-space-between">
          <v-btn text @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <h4 class="text-center" mandatory>
            {{ onboarding + 1 }} of {{ getMaps.length }}
          </h4>
          <v-btn text @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="crop" max-width="600px">
        <v-card style="width: 400">
          <div style="width: 400; height: 300px">
            <vue-cropper
              ref="cropper"
              :src="url"
              alt="Source Image"
              :aspectRatio="3 / 2"
              :img-style="{ width: '400px', height: '300px' }"
            />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeCrop()"> Cancel </v-btn>
            <v-btn id="btnsave" color="success" text @click="cropImage()">
              crop
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template>
          <v-card>
            <v-card-title
              v-if="create"
              class="headline red darken-2 white--text"
            >
              Add Map
            </v-card-title>
            <v-card-title v-else class="headline red darken-2 white--text">
              Edit Map
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="saveMap()"
                ref="mapData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="name"
                    id="id"
                    label="Name"
                    hide-details="auto"
                    v-model="mapData.name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                    class="mb-6"
                  ></v-text-field>
                  <v-row justify="center" align="start">
                    <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                      <v-avatar class="profile" color="grey" size="140" tile>
                        <img :src="url" />
                      </v-avatar>
                    </v-col>
                    <v-col
                      justify="center"
                      align="start"
                      cols="12"
                      xs="12"
                      sm="12"
                      md="7"
                      lg="7"
                    >
                      <span> Photo size less than 1 MB </span>
                      <v-row>
                        <v-col cols="6">
                          <v-btn
                            small
                            color="primary"
                            class="mt-5 white--text"
                            :loading="isSelecting"
                            @click="onButtonClickFile"
                          >
                            Upload
                            <v-icon right dark> mdi-cloud-upload </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            small
                            color="error"
                            class="mt-5 white--text"
                            v-if="isRemove"
                            @click="removeFile()"
                          >
                            Remove
                            <v-icon right dark> mdi-delete </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <input
                        ref="uploader"
                        type="file"
                        class="d-none"
                        @change="handleImage"
                        accept="image/*"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close()"> Cancel </v-btn>
              <v-btn id="btnsave" color="success" text @click="saveMap()">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="confirmDelete" max-width="300">
        <v-card>
          <v-card-title primary-title> Confirm Delete </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this map ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()"> Cancel </v-btn>

            <v-btn color="error" text @click="delMap()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDeleteAdd" max-width="300">
        <v-card>
          <v-card-title primary-title> Confirm Delete </v-card-title>

          <v-card-text class="body">
            Are you sure to delete this address ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmDeleteAdd = false"> Cancel </v-btn>

            <v-btn color="error" text @click="delAddress()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAddress" width="400" scrollable>
        <template>
          <v-card width="500">
            <v-card-title
              v-if="create"
              class="headline red darken-2 white--text"
            >
              Add Address
            </v-card-title>
            <v-card-title v-else class="headline red darken-2 white--text">
              Edit Address
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="saveAddress()"
                ref="addressData"
                lazy-validation
                enctype="multipart/form-data"
                autocomplete="off"
              >
                <v-container fluid>
                  <v-text-field
                    name="name"
                    id="id"
                    label="Name*"
                    hide-details
                    v-model="addressData.name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    name="address"
                    id="id"
                    label="Address*"
                    hide-details
                    v-model="addressData.address"
                    @keypress="isLetter($event)"
                    :rules="[(v) => !!v || 'Address is required']"
                    required
                  ></v-text-field>
                  <v-row class="mt-2" v-if="!create">
                    <v-col>
                      <v-btn small tile color="warning" @click="choosePoint()">
                        <v-icon left> mdi-pencil </v-icon>
                        Position
                      </v-btn>
                    </v-col>
                    <v-col class="text-end">
                      <v-btn
                        small
                        tile
                        color="error"
                        @click="delDataAdd(addressData.id)"
                      >
                        <v-icon left> mdi-delete </v-icon>
                        delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeEditAdd()"> Cancel </v-btn>
              <v-btn id="btnsave" color="success" text @click="saveAddress()">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-snackbar v-model="snackbar" top right :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      isShowPoint: false,
      indexDelete: null,
      crop: false,
      confirmDeleteAdd: false,
      editPoint: false,
      mapShow: null,
      id: null,
      onboarding: 0,
      oldX: 0,
      oldY: 0,
      isMobile: false,
      isApp: false,
      search: null,
      dialog: false,
      dialogAddress: false,
      createAdd: true,
      create: true,
      confirmDelete: false,
      text: "",
      color: "",
      map: {
        height: 0,
        width: 0,
        top: 0,
        left: 0,
      },
      mapData: {
        id: "",
        name: "",
        photo: "",
        building: "",
      },
      mapDefault: {
        id: "",
        name: "",
        photo: "",
        building: "",
      },
      idDelete: null,
      url: "https://awarethai.com/api/images/nopic.png",
      editedIndexMap: -1,
      editedIndexAdd: -1,
      isSelecting: false,
      isRemove: false,
      snackbar: false,
      image: null,
      oldAdd: null,
      addressData: {
        id: "",
        building: "",
        name: "",
        map: "",
        address: "",
        x: 0,
        y: 0,
      },
      addressDefault: {
        id: "",
        building: "",
        name: "",
        map: "",
        address: "",
        x: 0,
        y: 0,
      },
      headers: [
        { text: "Name", value: "name", sortable: true, width: "10%" },
        {
          text: "Photo",
          value: "photo",
          sortable: true,
          width: "50%",
          align: "center",
        },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Line",
          disabled: true,
          href: "/lineConfig",
        },
      ],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    getMaps() {
      return this.$store.getters.allMap;
    },
    getAddersses() {
      console.log(this.$store.getters.allAdd);
      return this.$store.getters.allAdd;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.$route.params.data != null) {
      this.name = this.$route.params.data.buildingName;
      this.id = this.$route.params.data.id;
      Cookies.set("nameBuilding", this.name);
      Cookies.set("idBuilding", this.id);
      Cookies.set("dataBuilding", this.$route.params.data);
    }
    this.isMobile =
      this.$device.mobile || this.$device.ios || this.$device.android;
    //console.log(this.isMobile);
  },
  mounted() {
    if (!this.currentUser || this.getAccess.role == "User") {
      this.$router.push("/");
    } else {
      this.loadMap();
    }
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    closeCrop() {
      this.crop = false;
      this.isRemove = false;
      this.url = "https://awarethai.com/api/images/nopic.png";
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas({}).toDataURL();

      //console.log(this.cropImg);
      this.url = this.cropImg;

      this.mapData.photo = this.cropImg;
      this.image = this.cropImg;
      this.crop = false;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    next() {
      this.onboarding =
        this.onboarding + 1 === this.getMaps.length ? 0 : this.onboarding + 1;
      ///console.log(this.onboarding);
      this.$store.dispatch("getAdd", this.getMaps[this.onboarding].id);
      this.mapShow = this.getMaps[this.onboarding].id;
      setTimeout(() => {
        var element = document.getElementById(this.getMaps[this.onboarding].id);
        if (element) {
          var positionInfo = element.getBoundingClientRect();
          this.map.height = positionInfo.height;
          this.map.width = positionInfo.width;
          this.map.top = positionInfo.top;
          this.map.left = positionInfo.left;
        }
      }, 1000);
      // console.log(this.map);
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.getMaps.length - 1 : this.onboarding - 1;
      this.$store.dispatch("getAdd", this.getMaps[this.onboarding].id);
      this.mapShow = this.getMaps[this.onboarding].id;
      setTimeout(() => {
        var element = document.getElementById(this.getMaps[this.onboarding].id);
        if (element) {
          var positionInfo = element.getBoundingClientRect();
          this.map.height = positionInfo.height;
          this.map.width = positionInfo.width;
          this.map.top = positionInfo.top;
          this.map.left = positionInfo.left;
        }
      }, 1000);
      console.log(this.map);
    },
    opendialog() {
      this.dialog = true;
      this.create = true;
      this.isRemove = false;
    },
    openAdd() {
      this.dialogAddress = true;
      this.createAdd = true;
    },
    close() {
      this.isSelecting = false;
      this.dialog = false;
      this.confirmDelete = false;
      this.mapData = Object.assign({}, this.mapDefault);
      this.editedIndexMap = -1;
      this.create = true;
      //this.$refs.mapData.resetValidation();
      //this.$refs.addressData.resetValidation();
      this.url = "https://awarethai.com/api/images/nopic.png";
    },
    closeEditAdd() {
      this.addressData = Object.assign({}, this.addressDefault);
      this.editedIndexAdd = -1;
      this.dialogAddress = false;
      this.editPoint = false;
      this.confirmDeleteAdd = false;
      this.create = true;
    },
    calPosition(data) {
      //console.log(this.map);
      let top = this.map.height * data.y;
      let left = this.map.width * data.x;
      return `top:${top}px; left:${left}px`;
    },
    calPositionX(data) {
      let left = data * this.map.width;
      return left;
    },
    calPositionY(data) {
      console.log(this.map.height);
      let top = data * this.map.height;
      return top;
    },
    onResize() {
      if (this.getMaps[this.onboarding]) {
        var element = document.getElementById(this.getMaps[this.onboarding].id);
        if (element) {
          var positionInfo = element.getBoundingClientRect();
          this.map.height = positionInfo.height;
          this.map.width = positionInfo.width;
          this.map.top = positionInfo.top;
          this.map.left = positionInfo.left;
        }
      }

      //console.log(this.map);
    },
    loadMap() {
      this.id = Cookies.get("idBuilding");
      console.log(this.id);
      this.$store.dispatch("getMaps", this.id).then((res) => {
        console.log(this.getMaps);
        if (res.length > 0) {
          this.mapShow = res[0].id;
          this.$store.dispatch("getAdd", res[0].id);
          setTimeout(() => {
            var element = document.getElementById(res[this.onboarding].id);
            if (element) {
              var positionInfo = element.getBoundingClientRect();
              this.map.height = positionInfo.height;
              this.map.width = positionInfo.width;
              this.map.top = positionInfo.top;
              this.map.left = positionInfo.left;
            }
            if (this.map.height == 0) {
              this.map.height = this.map.width / 1.5;
            }
            this.isShowPoint = true;
          }, 1000);
        }

        //console.log(this.map);
      });
    },
    selectMap() {
      const index = this.getMaps.findIndex((s) => s.id == this.mapShow);
      //console.log(index);
      this.onboarding = index;
      this.$store.dispatch("getAdd", this.getMaps[this.onboarding].id);
    },
    onButtonClickFile() {
      this.isSelecting = true;
      window.addEventListener("focus", () => {
        this.isSelecting = false;
      });
      window.addEventListener("touchend", () => {
        this.isSelecting = false;
      });
      this.$refs.uploader.click();
    },
    handleImage(e) {
      const file = e.target.files[0];
      if (file) {
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.isRemove = true;
            this.url = event.target.result;
            this.crop = true;
            // rebuild cropperjs with the updated source
            setTimeout(() => {
              this.$refs.cropper.replace(event.target.result);
            }, 100);
          };
          reader.readAsDataURL(file);
        }
      }
    },
    saveMap() {
      if (this.editedIndexMap > -1) {
        this.updateMap();
      } else {
        this.createMap();
      }
    },
    createMap() {
      this.mapData.building = this.id;
      //console.log(this.mapData);
      if (this.$refs.mapData.validate()) {
        this.mapData.photo = this.image;
        if (this.mapData.photo == null) {
          this.mapData.photo = "images/nopic.png";
        }
        this.$store.dispatch("createMap", this.mapData).then(
          () => {
            this.dialog = false;
            //console.log(res);

            this.onboarding = this.getMaps.length - 1;
            let index = this.getMaps.length;
            this.mapShow = this.getMaps[index - 1].id;
            var element = document.getElementById(
              this.getMaps[this.onboarding].id
            );
            setTimeout(() => {
              if (element) {
                var positionInfo = element.getBoundingClientRect();
                this.map.height = positionInfo.height;
                this.map.width = positionInfo.width;
                this.map.top = positionInfo.top;
                this.map.left = positionInfo.left;
              }
              this.isShowPoint = true;
            }, 1000);
            this.color = "success";
            this.text = "Map has been successfully saved.";
            this.snackbar = true;
          },
          (error) => {
            console.log(error.response.status);
            if (error.response.status == 413) {
              this.text = "Photo must size less than 1 MB";
            } else {
              this.text = error.response.data.error.message;
            }
            this.color = "error";
            this.snackbar = true;
          }
        );
      }
    },
    editMap(item) {
      //console.log(item);
      this.editedIndexMap = this.getMaps.indexOf(item);
      this.mapData.id = item.id;
      this.mapData = Object.assign({}, item);
      this.url = item.photo;

      if (item.photo.endsWith("https://awarethai.com/api/images/nopic.png")) {
        this.isRemove = false;
      } else {
        this.isRemove = true;
      }
      this.create = false;
      this.dialog = true;
    },
    updateMap() {
      //this.mapData.photo = this.image;
      console.log(this.mapData);
      this.mapData.building = this.id;
      if (
        this.mapData.photo == null ||
        this.mapData.photo == "https://awarethai.com/api/images/nopic.png"
      ) {
        this.mapData.photo = "images/nopic.png";
      }
      this.$store.dispatch("updateMap", this.mapData).then(
        () => {
          //this.$store.dispatch("getMaps", this.id);
          //this.$store.dispatch("getAdd", this.getMaps[this.editedIndexMap].id);
          setTimeout(() => {
            var element = document.getElementById("map");
            if (element) {
              var positionInfo = element.getBoundingClientRect();
              this.map.height = positionInfo.height;
              this.map.width = positionInfo.width;
              this.map.top = positionInfo.top;
              this.map.left = positionInfo.left;
            }
            this.isShowPoint = true;
          }, 1000);
          this.close();
          this.color = "green";
          this.text = "Map has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    delDataMap(id) {
      this.confirmDelete = true;
      this.idDelete = id;
      this.indexDelete = this.onboarding;
    },
    delMap() {
      this.$store.dispatch("deleteMap", this.idDelete).then(
        () => {
          this.confirmDelete = false;
          this.color = "green";
          this.text = "Your map has been deleted.";
          this.snackbar = true;
          this.onboarding = this.indexDelete - 1;
          if (this.getMaps.length > 0) {
            this.mapShow = this.getMaps[this.indexDelete - 1].id;
            this.$store.dispatch("getAdd", this.mapShow);
          }
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
        this.mapData.photo = this.image;
        //console.log(this.image);
      };
      reader.readAsDataURL(fileObject);
    },
    removeFile() {
      this.$refs.uploader.value = null;
      this.url = "https://awarethai.com/api/images/nopic.png";
      this.isRemove = false;
      this.mapData.photo = this.url;
    },
    saveAddress() {
      //console.log(this.editedIndexAdd);
      if (this.editedIndexAdd > -1) {
        this.updateAddress();
      } else {
        this.choosePoint();
      }
    },
    savePoint() {
      if (this.editedIndexAdd > -1) {
        this.updateAddress();
      } else {
        this.createAddress();
      }
    },
    choosePoint() {
      this.oldX = this.addressData.x;
      this.oldY = this.addressData.y;
      this.addressData.building = this.id;
      if (this.create) {
        this.$store.dispatch("checkAddress", this.addressData).then(
          () => {
            this.editPoint = true;
            this.dialogAddress = false;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      } else {
        this.addressData.address = this.oldAdd;
        this.editPoint = true;
        this.dialogAddress = false;
      }
    },
    editAddress(item) {
      //console.log(item);
      this.oldAdd = item.address;
      this.editedIndexAdd = this.getAddersses.indexOf(item);
      this.addressData = Object.assign({}, item);
      this.create = false;
      this.dialogAddress = true;
    },
    delDataAdd(id) {
      //console.log(id);
      this.confirmDeleteAdd = true;
      this.idDelete = id;
    },
    delAddress() {
      this.$store.dispatch("deleteAdd", this.idDelete).then(
        () => {
          this.closeEditAdd();
          this.color = "green";
          this.text = "Your address has been deleted.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    dragStop(newRect) {
      //console.log(newRect);
      this.addressData.y = newRect.top / this.map.height;
      this.addressData.x = newRect.left / this.map.width;
      //console.log(this.addressData.y);
      //console.log(this.addressData.x);
    },
    createAddress() {
      //console.log("ddd");
      console.log(this.addressData);
      this.addressData.building = Cookies.get("idBuilding");
      this.addressData.map = this.getMaps[this.onboarding].id;
      if (this.$refs.addressData.validate()) {
        this.$store.dispatch("createAddress", this.addressData).then(
          () => {
            //this.$store.dispatch("getAdd", this.getMaps[this.onboarding].id);
            this.closeEditAdd();
            this.color = "success";
            this.text = "Address has been successfully saved.";
            this.snackbar = true;
          },
          (error) => {
            this.color = "error";
            this.text = error.response.data.error.message;
            this.snackbar = true;
          }
        );
      }
    },
    updateAddress() {
      //this.addressData.photo = this.image;
      console.log(this.addressData);
      this.$store.dispatch("updateAddress", this.addressData).then(
        (res) => {
          //console.log(res);
          if (res.status >= 400) {
            if (res.data != undefined) {
              this.text = res.data.error.message;
            } else {
              this.text = error.message;
            }
            this.addressData.address = this.oldAdd;
            this.color = "red";
            this.snackbar = true;
          } else {
            //this.$store.dispatch("getAdd", this.getMaps[this.onboarding].id);
            this.closeEditAdd();
            this.color = "green";
            this.text = "Address has been modified.";
            this.snackbar = true;
          }
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
  },
};
</script>

<style>
.zoom {
  transition: transform 0.2s; /* Animation */
}
.zoom:hover {
  transform: scale(1.5);
  z-index: 9; /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
<style lang="scss" scoped>
.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-select {
  width: 250px;
  height: auto;
}
</style>
