import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "keywords/";
import authHeader from "../services/auth-header";
export const keywords = {
  state: {
    panel: [],
    zone: [],
  },
  getters: {
    allPanel: (state) => state.panel,
    allZone: (state) => state.zone,
  },
  mutations: {
    setKeyword(state, keywords) {
      state.zone = keywords.zone;
      state.panel = keywords.panel;
    },
    createZone(state, zone) {
      state.zone.push(zone);
    },
    createPanel(state, panel) {
      state.panel.push(panel);
    },
    updateZone(state, zone) {
      const index = state.zone.findIndex((u) => u._id === zone.id);
      state.zone.splice(index, 1, zone);
    },
    updatePanel(state, panel) {
      const index = state.panel.findIndex((u) => u._id === panel.id);
      state.panel.splice(index, 1, panel);
    },
    deleteZone(state, id) {
      const index = state.zone.findIndex((u) => u._id === id);
      state.zone.splice(index, 1);
    },
    deletePanel(state, id) {
      const index = state.panel.findIndex((u) => u._id === id);
      state.panel.splice(index, 1);
    },
  },
  actions: {
    async getKeyword(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setKeyword", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async createZone(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "zone/", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createZone", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return "Error: Sorry,that username already exists!";
        });
    },
    async createPanel(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "panel/", data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("createPanel", res.data.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return "Error: Sorry,that username already exists!";
        });
    },
    async updateZone({ commit }, data) {
      console.log(data);
      return axios
        .put(apiURL + "zone/" + data.id, data, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("updateZone", data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    async updatePanel({ commit }, data) {
      console.log(data);
      return axios
        .put(apiURL + "panel/" + data.id, data, { headers: authHeader() })

        .then((res) => {
          console.log(res);
          commit("updatePanel", data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    async deletePanel(context, id) {
      return axios
        .delete(apiURL + "panel/" + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
          context.commit("deletePanel", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async deleteZone(context, id) {
      return axios
        .delete(apiURL + "zone/" + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
          context.commit("deleteZone", id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
